import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import httpClient from 'app/utils/HttpClient';
import securityUtils from 'app/utils/SecurityUtils';
import { showErrorMessage } from 'app/store/fuse/messageSlice';
import { secureRequest, secureAsyncRequest } from 'app/store/docbase/commonAction';
import * as commonTypes from 'app/main/constants/CommonTypes';

export const getStorageDashboard = createAsyncThunk('system/getStorageDashboard', async () => {
	const respData = await httpClient.sendPost('/api/storageDashboard', {});
	if (!respData.result) {
		return {
			total: {},
			types: {},
			organizes: [],
			shareds: [],
			privates: [],
			collects: []
		};
	}
	return { dashBoardObj: respData.values[0] };
});

/**
 * 사용중인 문서 조회
 */
export const getHoldFiles = createAsyncThunk('system/getHoldFiles', async ({ userID }, { _, getState }) => {
	const secureObj = await secureAsyncRequest();
	const searchObj = await httpClient.sendPost('/api/holdFiles', {
		user_id: userID,
		token: secureObj.token
	});

	if (searchObj.result) {
		if (userID === '') {
			const files = [];
			searchObj.users.forEach(user => {
				user.files.forEach(file => {
					file.user_id = user.user_id;
					file.user_name = user.user_name;
					files.push(file);
				});
			});
			return { files };
		}

		return {
			files: searchObj.files.map(file => {
				file.user_id = userID;
				file.user_name = getState().docbase.admin.account.usersObj[userID].name;
				return file;
			})
		};
	}
	return { files: [] };
});

export const statisticValues = createAsyncThunk('system/statisticValues', async () => {
	const respData = await httpClient.sendPost('/api/statisticValues', {});
	if (!respData.result) {
		return {
			average_read_bytes: 0,
			average_write_bytes: 0,
			average_created_files: 0,
			average_renamed_files: 0,
			average_removed_files: 0,
			average_updated_files: 0,
			average_created_directories: 0,
			average_removed_directories: 0,
			average_webapi_calls: 0,
			connected_clients: 0
		};
	}
	return {
		average_read_bytes: respData.average_read_bytes,
		average_write_bytes: respData.average_write_bytes,
		average_created_files: respData.average_created_files,
		average_renamed_files: respData.average_renamed_files,
		average_removed_files: respData.average_removed_files,
		average_updated_files: respData.average_updated_files,
		average_created_directories: respData.average_created_directories,
		average_removed_directories: respData.average_removed_directories,
		average_webapi_calls: respData.average_webapi_calls,
		connected_clients: respData.connected_clients
	};
});

export const getAccessLog = createAsyncThunk(
	'system/getAccessLog',
	async ({ startID, date, days, path, operator, accessType, isToday }, { dispatch, _ }) => {
		// return {
		// 	logs: [
		// 		{
		// 			path: '/asdfa/adfasdf/asdfasdf',
		// 			dest_path: '/werwer/werwerwer/werwer/werwererw',
		// 			user_name: '이름',
		// 			user_id: 'userid',
		// 			access_type: 1,
		// 			accessed_at: '2020-08-26 11:11:11'
		// 		}
		// 	],
		// 	id: 0
		// };

		// dispatch(setShowBackdrop({ show: true }));
		const respData = await httpClient.sendPost('/api/fileAccessLogs', {
			start_id: startID && startID > 0 ? startID : 0,
			date,
			days,
			path,
			operator,
			access_types: accessType
		});
		// dispatch(setShowBackdrop({ show: false }));
		if (!respData.result) {
			return { logs: [], id: 0, startID, isToday };
		}
		if (startID !== 0 && respData.last_id === 0) {
			return { logs: [], id: startID, startID, isToday };
		}
		return { logs: respData.logs, id: respData.last_id + 1, startID, isToday };
	}
);

/**
 * 관리자 로그 검색
 */
export const getAuditLog = createAsyncThunk(
	'system/getAuditLog',
	async ({ page, auditType, startDate, endDate, object, user }, { dispatch, getState }) => {
		const secureObj = await secureAsyncRequest();
		const searchObj = await httpClient.sendPost('/api/getAuditLog', {
			rows_per_page: getState().docbase.admin.system.auditLogObj.rowsPerPage,
			audit_type: auditType,
			start_date: startDate,
			end_date: endDate,
			user,
			path: object,
			token: secureObj.token
		});
		if (searchObj.result) {
			dispatch(getAuditLogPage({ page }));
			return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages };
		}
		return { total_rows: 0, total_pages: 0 };
	}
);
/**
 * 관리자 로그 페이지 호출
 */
export const getAuditLogPage = createAsyncThunk('system/getAuditLogPage', async ({ page }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/getAuditLogPage', { page, token: secureObj.token });
	if (respData.result) {
		return { logs: respData.logs, page, aes: secureObj.aesKey };
	}
	return { logs: [], page: 1, aes: '' };
});
/**
 * 인사연동 정보 조회
 * 기본적인 인사연동 처리는 진행 하고 관리자가 관여해야하는 부분을 가져온다.
 */
export const getOsrInfo = createAsyncThunk('system/getOsrInfo', async (_, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/OSR', { token: secureObj.token });
	if (!respData.result) {
		return {
			new_organizations: [],
			remove_organizations: [],
			remove_users: [],
			error: true,
			exists: respData.error === commonTypes.SERVER_ERROR_EXISTS
		};
	}
	return respData.remains;
});
export const getLegacyIntegrateMethod = createAsyncThunk('system/getLegacyIntegrateMethod', async () => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/wcapi/getLegacyIntegrateMethod', {
		name: 'web:change_download_url',
		token: secureObj.token
	});
	return respData.method;
});

/**
 * 반출목록 검색
 */
export const inquiryTakeouts = createAsyncThunk('system/inquiryTakeouts', async ({ page }, { dispatch, getState }) => {
	const searchObj = await httpClient.sendPost('/api/inquiryTakeouts', {
		rows_per_page: getState().docbase.admin.system.takeoutsObj.rowsPerPage,
		duration: 0,
		duration_type: 4,
		inquiry_type: 0,
		status: 0
	});
	if (searchObj.result) {
		dispatch(inquiryTakeoutsPage({ page }));
		return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages };
	}
	return { total_rows: 0, total_pages: 0 };
});
/**
 * 반출목록 페이지 조회
 */
export const inquiryTakeoutsPage = createAsyncThunk('system/inquiryTakeoutsPage', async ({ page }, { dispatch, getState }) => {
	const secureObj = await secureAsyncRequest();
	const respData = await httpClient.sendPost('/api/inquiryTakeoutsPage', { page, token: secureObj.token });
	if (respData.result) {
		return { takeouts: respData.takeouts, page, aes: secureObj.aesKey };
	}
	return { takeouts: [], page: 1, aes: '' };
});

export const scrutinizeFolderSize = path => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/scrutinizeFolderSize', { path }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

export const procOsr = (addOrgObj, moveOrgObj, removeOrgObj, removeUserObj) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			const moveKeys = Object.keys(moveOrgObj);

			const rOrg = [];
			const newOrg = [];
			const rUser = [];
			Object.keys(removeOrgObj).forEach(rok => {
				let isDisabled = false;

				for (let i = 0; i < moveKeys.length; i++) {
					if (rok.indexOf(moveOrgObj[moveKeys[i]].path) === 0) {
						isDisabled = true;
						break;
					}
				}
				if (removeOrgObj[rok].path !== '' && !isDisabled) {
					if (removeOrgObj[rok].path === '#DELETE#') {
						rOrg.push({ path: rok });
					} else {
						rOrg.push({ path: rok, handover_path: removeOrgObj[rok].path });
					}
				}
			});

			Object.keys(addOrgObj).forEach(aok => {
				let isDisabled = false;
				for (let i = 0; i < moveKeys.length; i++) {
					if (addOrgObj[aok].path.indexOf(moveOrgObj[moveKeys[i]].newPath) === 0) {
						isDisabled = true;
						break;
					}
				}
				if (!isDisabled) {
					newOrg.push({ id: Number(aok) });
				}
			});

			Object.keys(removeUserObj).forEach(ruok => {
				if (removeUserObj[ruok].handover_user_id && removeUserObj[ruok].handover_user_id !== '') {
					if (removeUserObj[ruok].handover_user_id === '#DELETE#') {
						rUser.push({
							user_id: ruok
						});
					} else {
						rUser.push({
							user_id: ruok,
							handover_user_id: removeUserObj[ruok].handover_user_id
						});
					}
				}
			});

			const process = {
				new_organizations: newOrg,
				move_organizations: moveKeys.map(mok => {
					return { id: Number(mok), old_path: moveOrgObj[mok].path };
				}),
				remove_organizations: rOrg,
				remove_users: rUser
			};

			httpClient.sendPost('/api/OSRProcess', { process, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * osr 연동 조직 아이디를 조회한다.
 *
 * @returns
 */
export const getOSROrgs = () => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/getOSROrgs', {}).then(data => {
			if (data.result) {
				resolve(data.ids);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

/**
 * osr 연동 조직 아이디를 등록한다.
 * ids 는 ','분한한가 값
 *
 * @returns
 */
export const setOSROrgs = ids => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/setOSROrgs', { ids }).then(data => {
			if (data.result) {
				resolve(data);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

export const releaseUserHoldFiles = userID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/releaseUserHoldFiles', { user_id: userID, etd: 30, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 특정 경로 이하 피일들을 시스템에 편입시킨다.
 * 서버상에서 파일을 COODOC 내부로 가져왔을 경우 해당 세서에 대한 처리를한다.
 *
 * 요청시 큐에 값을 추가하고 작업은 비동로로 진행한다.
 * 완료는 알 수가없다.
 * @param {*} path
 * @returns
 */
export const naturalizeFiles = path => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/naturalizeFiles', { path, token: tk }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 * 엑셀 다운로드용 관리자 로그 페이지 호출
 */
export const getAuditLogWithExcel = async totalPage => {
	const logs = [];
	for (let i = 1; i <= totalPage; i++) {
		// eslint-disable-next-line no-await-in-loop
		const secureObj = await secureAsyncRequest();
		// eslint-disable-next-line no-await-in-loop
		const respData = await httpClient.sendPost('/api/getAuditLogPage', { page: i, token: secureObj.token });
		if (respData.result) {
			respData.logs.forEach(log => {
				log.extra = securityUtils.decryptAES(secureObj.aesKey, log.extra, 'hex', 'utf8');
				log.new_object = securityUtils.decryptAES(secureObj.aesKey, log.new_object, 'hex', 'utf8');
				log.old_object = securityUtils.decryptAES(secureObj.aesKey, log.old_object, 'hex', 'utf8');
				log.opreator = securityUtils.decryptAES(secureObj.aesKey, log.opreator, 'hex', 'utf8');
				logs.push(log);
			});
		}
	}
	return logs;
};

export const setRecommendUpdateNewClient = (userID, fid, recommendType) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/setRecommendUpdateNewClient', { user_id: userID, fid, recommend_type: recommendType, token: tk })
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

export const getRecommendUpdateNewClient = (userID, fid) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/getRecommendUpdateNewClient', { user_id: userID, fid, token: tk }).then(data => {
				if (data.result) {
					resolve(data.users);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

export const installedClientsInfo = userID => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/clientsInfo', { user_id: userID, token: tk }).then(data => {
				if (data.result) {
					resolve(data.clients);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

export const getApprovalTakeoutManageTypes = () => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/getApprovalTakeoutManageTypes', {}).then(data => {
			if (data.result) {
				resolve(data.folders);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};
export const setApprovalTakeoutManageTypes = folders => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/setApprovalTakeoutManageTypes', { folders }).then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

export const getAutoApprovalSettingsForFolder = fid => {
	return new Promise((resolve, reject) => {
		httpClient.sendPost('/api/getAutoApprovalSettingsForFolder', { fid }).then(data => {
			if (data.result) {
				resolve(data.users);
			} else {
				httpClient.printError(data);
				reject(data);
			}
		});
	});
};

export const setAutoApprovalSettingsForFolder = (fid, users) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/setAutoApprovalSettingsForFolder', { fid, users, token: tk }).then(data => {
				if (data.result) {
					resolve(data.result);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
 *
 * @param {*} userID
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} omitServer  true: 서버로그 숮수집
 * @returns
 */
export const collectLogs = (userID, startDate, endDate, omitServer) => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient
				.sendPost('/api/collectLogs', {
					user_id: userID,
					start_date: startDate,
					end_date: endDate,
					omit_server_log: omitServer
				})
				.then(data => {
					if (data.result) {
						resolve(data);
					} else {
						httpClient.printError(data);
						reject(data);
					}
				});
		});
	});
};

export const collectLogsStatus = () => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/collectLogsStatus', {}).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

/**
{
"file_types": ["engine", "collector", "agent", "server"],
"log_types": ["trace", "info", "warn", "err", "critical"],
"includes": ["include text-1", "include text-2"],
"excludes": ["exclude text-1", "exclude text-2"],
"start_time": "2020-01-01 10:00:00",
"end_time": "2020-01-01 20:00:00",
"rows_per_page": 100
}

file_type => 조회할 로그파일의 종류
“engine” : 조회 결과에 Client의 engine 로그가 포함된다.
“collector” : 조회 결과에 Client의 collcector 로그가 포함된다.
“agent” : 조회 결과에 Client의 agent 로그가 포함된다.
“server” : 조회 결과에 Server 로그가 포함된다.

log_types => 조회할 로그의 종류
“trace”  : Trace log가 조회 대상에 포함된다.
“info” : Information log가 조회 대상에 포함된다.
“err” : Error log가 조회 대상에 포함된다.
“critical” : Critical log가 조회 대상에 포함된다.


includes => 검색 포함 문자열
excludes => 검색 제외 문자열
*/
export const getCollectedLogs = conditions => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/getCollectedLogs', { conditions }).then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};

export const getCollectedLogsPage = page => {
	return new Promise((resolve, reject) => {
		secureRequest((aes, tk) => {
			httpClient.sendPost('/api/getCollectedLogsPage', { page }).then(data => {
				if (data.result) {
					resolve(data.logs);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
		});
	});
};
/**
 * COODOC 클라이언트 실행 로그를 조회한다. 호출한 후 clientRunningLogsPage를 호출해야만 실제 자료를 조회할 수 있다.
 * 클라이언트 실행 로그는 클라이언트의 실행과 종료에 관련된 로그만 저장되며, 클라이언트 프로그램의 비정상 종료 등을 검토하는데 사용될 수 있다.
 *
 * @param {string} userID
 * @param {string} startDateTime - YYYY-MM-DD hh:mm:ss
 * @param {string} endDateTime - YYYY-MM-DD hh:mm:ss
 * @param {int} rowsPerPage
 * @returns
 */
export const getClientRunningLogs = (userID, startDateTime, endDateTime, rowsPerPage) => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/api/clientRunningLogs', {
				user_id: userID,
				start_time: startDateTime,
				end_time: endDateTime,
				rows_per_page: rowsPerPage
			})
			.then(data => {
				if (data.result) {
					resolve(data);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};
export const getClientRunningLogsPage = page => {
	return new Promise((resolve, reject) => {
		httpClient
			.sendPost('/api/clientRunningLogsPage', {
				page
			})
			.then(data => {
				if (data.result) {
					resolve(data.logs);
				} else {
					httpClient.printError(data);
					reject(data);
				}
			});
	});
};

/**
 * 오프라인 드라이브 반출 파일 조회
 */
export const offlineDriveTakeoutFiles = createAsyncThunk(
	'system/offlineDriveTakeoutFiles',
	async ({ page, day, userID }, { dispatch, getState }) => {
		const secureObj = await secureAsyncRequest();
		const searchObj = await httpClient.sendPost('/api/offlineDriveTakeoutFiles', {
			rows_per_page: getState().docbase.admin.system.offlineTakeoutsObj.rowsPerPage,
			duration: day,
			duration_type: 4,
			user_id: userID,
			token: secureObj.token
		});
		if (searchObj.result) {
			dispatch(offlineDriveTakeoutFilesPage({ page }));
			return { total_rows: searchObj.total_rows, total_pages: searchObj.total_pages };
		}
		return { total_rows: 0, total_pages: 0 };
	}
);
/**
 * 오프라인 드라이브 반출 파일 조회 목록 가져오기
 */
export const offlineDriveTakeoutFilesPage = createAsyncThunk(
	'system/offlineDriveTakeoutFilesPage',
	async ({ page }, { dispatch, getState }) => {
		const secureObj = await secureAsyncRequest();
		const docPages = await httpClient.sendPost('/api/offlineDriveTakeoutFilesPage', { page, token: secureObj.token });
		if (docPages.result) {
			return { takeouts: docPages.takeouts, page, aes: secureObj.aesKey };
		}
		return { takeouts: [], page: 1, aes: '' };
	}
);

const settingSlice = createSlice({
	name: 'system',
	initialState: {
		dashboardObj: {
			total: {},
			types: {
				others: { size: 0 },
				organizes: { size: 0 },
				privates: { size: 0 },
				shareds: { size: 0 },
				collects: { size: 0 }
			},
			organizes: [],
			shareds: [],
			privates: [],
			collects: []
		},
		averageInfo: {
			time: new Date(),
			readBytes: 0,
			writeBytes: 0,
			average_created_files: 0,
			average_renamed_files: 0,
			average_removed_files: 0,
			average_updated_files: 0,
			average_created_directories: 0,
			average_removed_directories: 0,
			average_webapi_calls: 0,
			connected_clients: 0
		},
		todayLogs: [],
		todayLogID: 0,
		osrObj: {
			new_organizations: [],
			remove_organizations: [],
			remove_users: [],
			error: true,
			exists: false
		},
		auditLogObj: {
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			totalPages: 0,
			logs: []
		},
		accessLogObj: {
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			totalPages: 0,
			logs: []
		},
		legacyObj: {},
		takeoutsObj: {
			key: '',
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			total_pages: 0,
			takeouts: []
		},
		offlineTakeoutsObj: {
			rowsPerPage: 100,
			currentPage: 1,
			totalRows: 0,
			total_pages: 0,
			takeouts: []
		},
		holdFiles: []
	},
	reducers: {
		changeAuditLogRowsPerPage: (state, action) => {
			state.auditLogObj.rowsPerPage = action.payload.value;
		},
		changeTakeoutsRowsPerPage: (state, action) => {
			state.takeoutsObj.rowsPerPage = action.payload.value;
		},
		changeOfflineTakeoutsRowsPerPage: (state, action) => {
			state.offlineTakeoutsObj.rowsPerPage = action.payload.value;
		}
	},
	extraReducers: {
		[getStorageDashboard.fulfilled]: (state, action) => {
			state.dashboardObj = action.payload.dashBoardObj;
		},
		[statisticValues.fulfilled]: (state, action) => {
			state.averageInfo.time = new Date();
			state.averageInfo.readBytes = action.payload.average_read_bytes;
			state.averageInfo.writeBytes = action.payload.average_write_bytes;
			state.averageInfo.average_created_files = action.payload.average_created_files * 10;
			state.averageInfo.average_renamed_files = action.payload.average_renamed_files * 10;
			state.averageInfo.average_removed_files = action.payload.average_removed_files * 10;
			state.averageInfo.average_updated_files = action.payload.average_updated_files * 10;
			state.averageInfo.average_created_directories = action.payload.average_created_directories * 10;
			state.averageInfo.average_removed_directories = action.payload.average_removed_directories * 10;
			state.averageInfo.average_webapi_calls = action.payload.average_webapi_calls;
			state.averageInfo.connected_clients = action.payload.connected_clients;
		},
		[getAccessLog.fulfilled]: (state, action) => {
			//	사용기록은 statID가 0이 아니다
			if (action.payload.isToday) {
				state.todayLogs = action.payload.logs;
				state.todayLogID = action.payload.id;
			} else {
				state.accessLogObj.logs = action.payload.logs;
			}
		},
		[getAuditLog.fulfilled]: (state, action) => {
			state.auditLogObj.totalRows = action.payload.total_rows;
			state.auditLogObj.totalPages = action.payload.total_pages;
		},
		[getAuditLogPage.fulfilled]: (state, action) => {
			state.auditLogObj.logs = action.payload.logs.map(log => {
				log.extra = securityUtils.decryptAES(action.payload.aes, log.extra, 'hex', 'utf8');
				log.new_object = securityUtils.decryptAES(action.payload.aes, log.new_object, 'hex', 'utf8');
				log.old_object = securityUtils.decryptAES(action.payload.aes, log.old_object, 'hex', 'utf8');
				log.opreator = securityUtils.decryptAES(action.payload.aes, log.opreator, 'hex', 'utf8');
				return log;
			});
			state.auditLogObj.currentPage = action.payload.page;
		},
		[getOsrInfo.fulfilled]: (state, action) => {
			state.osrObj = action.payload;
		},
		[getLegacyIntegrateMethod.fulfilled]: (state, action) => {
			state.legacyObj = action.payload;
		},
		[inquiryTakeouts.fulfilled]: (state, action) => {
			state.takeoutsObj.totalRows = action.payload.total_rows;
			state.takeoutsObj.totalPages = action.payload.total_pages;
		},
		[inquiryTakeoutsPage.fulfilled]: (state, action) => {
			state.takeoutsObj.key = action.payload.aes;
			let tmpName = '';
			state.takeoutsObj.takeouts = action.payload.takeouts.map(takeout => {
				takeout.requester = securityUtils.decryptAES(action.payload.aes, takeout.requester, 'hex', 'utf8');
				takeout.approver = securityUtils.decryptAES(action.payload.aes, takeout.approver, 'hex', 'utf8');

				tmpName = securityUtils.decryptAES(action.payload.aes, takeout.requester_name, 'hex', 'utf8');
				takeout.requester_name = tmpName === 'Unknown' ? '-' : tmpName;
				tmpName = securityUtils.decryptAES(action.payload.aes, takeout.approver_name, 'hex', 'utf8');
				takeout.approver_name = tmpName === 'Unknown' ? '-' : tmpName;

				takeout.requested_at = securityUtils.decryptAES(action.payload.aes, takeout.requested_at, 'hex', 'utf8');
				takeout.approved_at = securityUtils.decryptAES(action.payload.aes, takeout.approved_at, 'hex', 'utf8');
				takeout.reason_requested = securityUtils.decryptAES(action.payload.aes, takeout.reason_requested, 'hex', 'utf8');
				takeout.reason_rejected = securityUtils.decryptAES(action.payload.aes, takeout.reason_rejected, 'hex', 'utf8');
				return takeout;
			});
			state.takeoutsObj.currentPage = action.payload.page;
		},
		[offlineDriveTakeoutFiles.fulfilled]: (state, action) => {
			state.offlineTakeoutsObj.totalRows = action.payload.total_rows;
			state.offlineTakeoutsObj.totalPages = action.payload.total_pages;
		},
		[offlineDriveTakeoutFilesPage.fulfilled]: (state, action) => {
			state.offlineTakeoutsObj.takeouts = action.payload.takeouts.map(takeout => {
				takeout.user_id = securityUtils.decryptAES(action.payload.aes, takeout.user_id, 'hex', 'utf8');
				takeout.user_name = securityUtils.decryptAES(action.payload.aes, takeout.user_name, 'hex', 'utf8');
				takeout.copied_at = securityUtils.decryptAES(action.payload.aes, takeout.copied_at, 'hex', 'utf8');
				takeout.path = securityUtils.decryptAES(action.payload.aes, takeout.path, 'hex', 'utf8');
				return takeout;
			});
			state.offlineTakeoutsObj.currentPage = action.payload.page;
		},
		[getHoldFiles.fulfilled]: (state, action) => {
			state.holdFiles = action.payload.files;
		}
	}
});

export const { changeAuditLogRowsPerPage, changeTakeoutsRowsPerPage, changeOfflineTakeoutsRowsPerPage } = settingSlice.actions;

export default settingSlice.reducer;
