import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogActions, DialogContent, Button, Typography, IconButton, TextField, MenuItem } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles({
	root: {
		'& .MuiDialog-paper': { width: 380 }
	},
	title: {
		color: '#000000',
		fontSize: 22,
		padding: 30,
		textAlign: 'center'
	},
	content: {
		color: '#404040',
		fontSize: 14,
		padding: '0 30px',
		textAlign: 'center',
		'& .input-wrapper': {
			marginTop: 20,
			display: 'flex',
			alignItems: 'center'
		},
		'& .title': {
			cursor: 'default',
			width: '100%'
		},
		'& .input-item': { width: '100%', paddingLeft: 18, minWidth: 210, '& .MuiTextField-root': { textAlign: 'left' } }
	},
	actions: {
		padding: 30,
		justifyContent: 'center',
		'& button': { width: '100%', minWidth: 130 },
		'& button:first-child': {
			marginRight: 8
		}
	}
});

export default function ConfirmDialog(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();
	const hours = [];
	for (let i = 0; i < new Date().getHours(); i++) {
		hours.push(i);
	}
	return (
		<Dialog open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title" className={classes.root}>
			<DialogTitle className={classes.title} onClose={props.fnHandleClose}>
				{props.title}
			</DialogTitle>
			<DialogContent className={classes.content}>
				<div className="items-center justify-center w-full">
					<p>{props.content_1}</p>
					<p>{props.content_2}</p>
				</div>
				{props.mode === 1 ? (
					<div className="input-wrapper">
						<div className="cursor-default title">{t('RESTORE_MESSAGE_9')}</div>
						<div className="input-item">
							<TextField
								select
								className=""
								placeholder={t('TIME')}
								disableUnderline
								value={props.backHours}
								variant="outlined"
								fullWidth
								onChange={e => props.fnSetBackHours(e.target.value)}
							>
								{hours.map((h, idx) => {
									if (h === 0) {
										return <MenuItem value={0}>{t('RESTORE_NOW')}</MenuItem>;
									}
									return <MenuItem value={h}>{`${h}${t('RESTORE_HOUR_BEFORE')}`}</MenuItem>;
								})}
							</TextField>
						</div>
					</div>
				) : (
					<></>
				)}
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button onClick={props.fnHandleCancel} variant="contained" className="default" aria-label="cancel" type="button">
					{props.cancelTitle}
				</Button>
				<Button
					className=""
					color="secondary"
					type="button"
					variant="contained"
					aria-label="ok"
					onClick={props.fnHandleOK}
				>
					{props.okTitle}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
