import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	Paper,
	Tooltip,
	IconButton,
	TextField,
	MenuItem
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomIcon from 'app/main/icon/CustomIcon';
import { getAuditLogPage, changeAuditLogRowsPerPage } from 'app/store/docbase/admin/systemSlice';
import commonUtils from 'app/utils/CommonUtils';
import * as commonTypes from 'app/main/constants/CommonTypes';
import LogsTableHead from './LogsTableHead';

const useStyles = makeStyles(theme => ({
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 130,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

function LogsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { sysConfigure } = useSelector(({ docbase }) => docbase.admin.setting);
	const { logs, rowsPerPage, currentPage, totalRows } = useSelector(({ docbase }) => docbase.admin.system.auditLogObj);

	const [auditTypes, setAuditTypes] = React.useState([
		{ type: 0, cons: 'AUDIT_TYPE_ALL' },
		{ type: 1, cons: 'AUDIT_TYPE_LOGGED_IN' },
		{ type: 2, cons: 'AUDIT_TYPE_LOGGED_OFF' },
		{ type: 3, cons: 'AUDIT_TYPE_CREATE_USER' },
		{ type: 4, cons: 'AUDIT_TYPE_MODIFY_USER' },
		{ type: 5, cons: 'AUDIT_TYPE_MODIFY_PASSWORD' },
		{ type: 6, cons: 'AUDIT_TYPE_REMOVE_USER' },
		{ type: 8, cons: 'AUDIT_TYPE_CREATE_ORGANIZATION' },
		{ type: 9, cons: 'AUDIT_TYPE_RENAME_ORGANIZATION' },
		{ type: 10, cons: 'AUDIT_TYPE_TRANSFER_ORGANIZATION' },
		{ type: 11, cons: 'AUDIT_TYPE_MOVE_ORGANIZATION_DATA' },
		{ type: 12, cons: 'AUDIT_TYPE_REMOVE_ORGANIZATION' },
		{ type: 13, cons: 'AUDIT_TYPE_CREATE_SHARED_FOLDER' },
		{ type: 14, cons: 'AUDIT_TYPE_RENAME_SHARED_FOLDER' },
		{ type: 15, cons: 'AUDIT_TYPE_REMOVE_SHARED_FOLDER' },
		{ type: 19, cons: 'AUDIT_TYPE_CREATE_ORGSHARED_FOLDER' },
		{ type: 20, cons: 'AUDIT_TYPE_REMOVE_ORGSHARED_FOLDER' },
		{ type: 21, cons: 'AUDIT_TYPE_RESET_FOLDER_PERMISSION' },
		{ type: 16, cons: 'AUDIT_TYPE_ADD_FOLDER_PERMISSION' },
		{ type: 17, cons: 'AUDIT_TYPE_UPDATE_FOLDER_PERMISSION' },
		{ type: 18, cons: 'AUDIT_TYPE_REMOVE_FOLDER_PERMISSION' },
		{ type: 24, cons: 'AUDIT_TYPE_SYSCONFIG_CHANGED' }
	]);

	useEffect(() => {
		if (sysConfigure.menu_support_type !== 2) {
			setAuditTypes([
				{ type: 0, cons: 'AUDIT_TYPE_ALL' },
				{ type: 1, cons: 'AUDIT_TYPE_LOGGED_IN' },
				{ type: 2, cons: 'AUDIT_TYPE_LOGGED_OFF' },
				{ type: 3, cons: 'AUDIT_TYPE_CREATE_USER' },
				{ type: 4, cons: 'AUDIT_TYPE_MODIFY_USER' },
				{ type: 5, cons: 'AUDIT_TYPE_MODIFY_PASSWORD' },
				{ type: 6, cons: 'AUDIT_TYPE_REMOVE_USER' },
				{ type: 7, cons: 'AUDIT_TYPE_CREATE_PAPER_PASSWORD' },
				{ type: 8, cons: 'AUDIT_TYPE_CREATE_ORGANIZATION' },
				{ type: 9, cons: 'AUDIT_TYPE_RENAME_ORGANIZATION' },
				{ type: 10, cons: 'AUDIT_TYPE_TRANSFER_ORGANIZATION' },
				{ type: 11, cons: 'AUDIT_TYPE_MOVE_ORGANIZATION_DATA' },
				{ type: 12, cons: 'AUDIT_TYPE_REMOVE_ORGANIZATION' },
				{ type: 13, cons: 'AUDIT_TYPE_CREATE_SHARED_FOLDER' },
				{ type: 14, cons: 'AUDIT_TYPE_RENAME_SHARED_FOLDER' },
				{ type: 15, cons: 'AUDIT_TYPE_REMOVE_SHARED_FOLDER' },
				{ type: 19, cons: 'AUDIT_TYPE_CREATE_ORGSHARED_FOLDER' },
				{ type: 20, cons: 'AUDIT_TYPE_REMOVE_ORGSHARED_FOLDER' },
				{ type: 21, cons: 'AUDIT_TYPE_RESET_FOLDER_PERMISSION' },
				{ type: 16, cons: 'AUDIT_TYPE_ADD_FOLDER_PERMISSION' },
				{ type: 17, cons: 'AUDIT_TYPE_UPDATE_FOLDER_PERMISSION' },
				{ type: 18, cons: 'AUDIT_TYPE_REMOVE_FOLDER_PERMISSION' },
				{ type: 22, cons: 'AUDIT_TYPE_REGISTER_SECURED_PROCESS' },
				{ type: 23, cons: 'AUDIT_TYPE_UNREGISTER_SECURED_PROCESS' },
				{ type: 24, cons: 'AUDIT_TYPE_SYSCONFIG_CHANGED' },
				{ type: 25, cons: 'AUDIT_TYPE_CREATE_SUB_USER' }
			]);
		}
	}, sysConfigure);
	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {}, [dispatch]);

	if (props.loading) {
		return <FuseLoading />;
	}

	function handleChangePage(event, value) {
		props.setLoading(true);
		dispatch(getAuditLogPage({ page: value + 1 })).then(() => props.setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		dispatch(changeAuditLogRowsPerPage({ value: event.target.value }));
		props.fnDoSearch();
	}
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="">
							<span className="title">{t('AUDIT_TYPE')}</span>
							<TextField
								select
								className="select"
								value={props.searchObj.auditType}
								fullWidth
								variant="outlined"
								size="small"
								onChange={e => {
									props.fnSetSearchObj('auditType', e.target.value);
								}}
							>
								{auditTypes.map(at => (
									<MenuItem value={at.type}>{t(at.cons)}</MenuItem>
								))}
							</TextField>
						</div>
						<div className="date-picker-wrapper">
							<span className="title">{t('RESTORE_DAY')}</span>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="start_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.startDate}
										onChange={d => {
											// eslint-disable-next-line no-restricted-globals
											if (!isNaN(d.getTime())) {
												if (props.searchObj.startDate.getTime() !== d.getTime())
													props.fnSetSearchObj('startDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('startDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date start'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="tilde">~</div>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="end_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.endDate}
										onChange={d => {
											// eslint-disable-next-line no-restricted-globals
											if (!isNaN(d.getTime())) {
												if (props.searchObj.endDate.getTime() !== d.getTime())
													props.fnSetSearchObj('endDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('endDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date end'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => props.fnDoSearch()}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<LogsTableHead
							searchObj={props.searchObj}
							rowCount={logs.length}
							fnSearch={(ob, user) => {
								if (props.searchObj.object !== ob) {
									props.fnSetSearchObj('object', ob);
								}
								if (props.searchObj.user !== user) {
									props.fnSetSearchObj('user', user);
								}
							}}
						/>

						<TableBody>
							{logs.map((log, idx) => {
								let { extra } = log;
								if (log.audit_type === 1) {
									switch (Number(extra)) {
										case commonTypes.CLIENT_TYPE_WIN_86:
											extra = 'windows86';
											break;
										case commonTypes.CLIENT_TYPE_WIN_64:
											extra = 'windows64';
											break;
										case commonTypes.CLIENT_TYPE_MAC:
											extra = 'MAC';
											break;
										case commonTypes.CLIENT_TYPE_WEB:
											extra = 'Web';
											break;
										default:
											extra = '...';
									}
								}

								return (
									<TableRow hover className="cursor-default" role="checkbox" tabIndex={-1} key={idx}>
										<TableCell className="left" align="left" component="td" scope="row">
											{sysConfigure.language === 'korean'
												? log.audit_type_name_korean
												: log.audit_type_name}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{log.opreator}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{log.new_object}
										</TableCell>
										<TableCell className="left" align="left" component="td" scope="row">
											{extra}
										</TableCell>

										<TableCell className="" align="center" component="td" scope="row">
											{commonUtils.getDateStringToLocalDate(log.performed_at)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
				<TablePagination
					className="flex-shrink-0"
					component="div"
					count={totalRows}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[100, 250, 500]}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(event, value) => {
						handleChangePage(event, value);
						contentTable.current.scrollTop = 0;
					}}
					onChangeRowsPerPage={event => {
						handleChangeRowsPerPage(event);
						contentTable.current.scrollTop = 0;
					}}
				/>
			</Paper>
		</div>
	);
}

export default LogsTable;
