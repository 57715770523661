const locale = {
	CONFIRM: '확인',
	USE: '사용',
	UNUSED: '미사용',
	APPLY: '적용',
	UNAPPLIED: '미적용',
	CANCEL: '취소',
	ADD: '추가',
	CHANGE: '변경',
	DELETE: '삭제',
	REMOVE: '제거',
	MOVE: '이동',
	YES: '예',
	NO: '아니요',
	CLOSE: '닫기',
	MODIFY: '수정',
	TIME: '시간',
	PERMIT: '허용',
	BLOCK: '차단',
	UNSELECTED: '미선택',
	MONTH: '월',
	DAY: '일',
	HOUR: '시',
	MINUTE: '분',
	SEC: '초',
	CHECK_ALL: '모두체크',
	NOTE: '비고',
	IP_ADDRESS: '아이피',
	LOGIN_ERROR_MESSAGE_1: '아이디 또는 암호를 확인하세요',
	LOGIN_ERROR_MESSAGE_2: '인증에 실패하였습니다',
	LOGIN_ERROR_MESSAGE_3: '입력 시간을 초과하였습니다',
	LOGIN_ERROR_MESSAGE_4: '이미 등록 되어있습니다',
	LOGIN_ERROR_MESSAGE_5: '잘못된 입력입니다',
	LOGIN_ERROR_MESSAGE_6: '관리자 계정으로만 접속이 가능합니다',
	TOTP_SETTING: 'TOTP 설정',
	TOTP_AUTH: 'TOTP 인증 사용',
	TOTP_AUTH_USED: 'TOTP 인증 사용중',
	TOTP_SETTINGKEY: '설정키',
	TOTP_VALUE: 'TOTP 값',
	TOTP_MESSAGE_1: 'TOTP를 2차 인증으로 사용하시겠습니까?',
	TOTP_MESSAGE_2: '입력시간을 초과하였습니다',
	LOGOUT: '로그아웃',
	LOGOUT_MESSAGE_1: '로그아웃 하시겠습니까?',
	ID: '아이디',
	PASSWORD: '암호',
	NAME: '이름',
	EMAIL: '이메일',
	PASSWORD_CONFIRM: '암호 (확인)',
	PASSWORD_AUTO: '암호 자동생성',
	ACCESS_LEVEL: '접근레벨',
	LEVEL: '레벨',
	CREATE: '생성',
	REMEMBER_ME: '아이디 저장',
	REMEMBER_PW: '비밀번호 저장',
	LOGIN: '로그인',
	PRINT_PASSWORD: '종이암호',
	PRINT_PASSWORD_AUTH: '종이암호 인증',
	PRINT_PASSWORD_CONTEXT:
		'종이 암호에서 "%s" 에 위치하는 3개의 값을 공백 없이 연속적으로 입력하세요. 종이 암호는 관리자를 통해서 얻을 수 있으며 TOTP를 사용할 수 없는 경우에 한해 하루만 유효한 암호표입니다',
	PRINT_PASSWORD_ERROR_MESSAGE_1: '발행된 종이암호가 없습니다. 관리자에게 문의하세요',
	PRINT_PASSWORD_ERROR_MESSAGE_2: '잘못된 종이암호 입니다',
	CREATE_ADMIN: '관리자 생성',
	SERVER_ENCRYPT: '서버 파일 암호화',
	LANGUAGE: '언어',
	REGISTER_ERROR_MESSAGE_1: '등록에 실패하였습니다',
	REGISTER_PRODUCT: '제품 등록',
	REGISTER: '등록',
	ADMIN_ERROR_MESSAGE_1: '관리자 등록에 실패하였습니다',
	ADMIN_ERROR_MESSAGE_2: '관리자가 등록 되어있습니다',
	SHARED_FOLDER: '공유폴더',
	ORG_FOLDER: '조직폴더',
	PRIVATE_FOLDER: '개인폴더',
	COLLECTOR_FOLDER: '수집폴더',
	ORGSHARED_FOLDER: '조직공유폴더',
	ORGSHARED: '조직공유',
	ETC: '기타',
	FOLDER_NAME: '폴더명',
	USER: '사용자',
	USER_TITLE: '사용자',
	USER_ADD: '사용자 추가',
	USER_SELECT: '사용자 선택',
	ORG_TITLE: '조직',
	ORG_ADD: '조직 추가',
	ORG_NAME: '조직명',
	ORG_TOP: '조직 경로',
	SHARED_TITLE: '공유',
	SECUREDOC_TITLE: '보안문서',
	PERM_NO_FOLDER: '추가 가능한 폴더가 없습니다',
	PERM_NO_USER: '추가 가능한 사용자가 없습니다',
	PERM_FOLDER_INPUT: '폴더권한 추가',
	PERM_USER_INPUT: '사용자권한 추가',
	PERM_READ: '읽기전용',
	PERM_READ_WRITE: '쓰기허용',
	PERM_TRASH: '휴지통완전삭제',
	PERM_TRASH_POSSIBLE: '휴지통완전삭제 가능',
	PERM_TRASH_IMPOSSIBLE: '휴지통완전삭제 불가능',
	PERM_NONE: '숨김',
	PERM_NOT_NONE: '노출',
	PERM_DELETE: '권한삭제',
	PERM_INPUT_TEXT: '사용자 검색',
	PERM_FOLDER_TITLE: '폴더권한 추가',
	NO_USER: '사용자가 없습니다',
	USER_TOTP_TITLE: '사용자 TOTP 초기화',
	USER_TOTP_CONTENT: '사용자의 TOTP정보를 초기화하시겠습니까?',
	DELETE_FOLDER_TITLE: '폴더 삭제',
	DELETE_CONTENT: '를 삭제하시겠습니까?',
	MULTIPLE_LOGINS: '중복로그인',
	USER_DELETE_CONTENT_1: '삭제하는 사용자의 개인폴더 및 수집폴더를 특정 사용자에게 이동시킬 수 있습니다',
	USER_DELETE_CONTENT_2: '삭제하는 사용자의 폴더 및 파일을 받을 사용자를 선택해주세요.(미지정시 폴더 및 파일은 완전삭제 됩니다',
	USER_MENU_1: '암호 재설정',
	USER_MENU_2: '종이암호 생성',
	USER_MENU_3: 'TOTP해제',
	USER_MESSAGE_1: '사용자 추가 완료',
	USER_MESSAGE_4: '이름을 변경하였습니다',
	USER_MESSAGE_5: '이메일을 변경하였습니다',
	USER_MESSAGE_6: '접근레벨을 변경하였습니다',
	USER_MESSAGE_7: '암호를 재설정하였습니다',
	USER_MESSAGE_8: 'TOTP정보를 초기화하였습니다',
	USER_MESSAGE_9: '사용자 삭제 완료',
	USER_MESSAGE_10: '인사연동에서 제외하였습니다',
	USER_MESSAGE_11: '인사연동에 포함하였습니다',
	USER_MESSAGE_12: '수동 등록 사용자를 변경하였습니다',
	USER_MESSAGE_13: '중복 로그인을 변경하였습니다',
	USER_MESSAGE_14: '개인폴더 반출 정책을 변경하였습니다',
	USER_MESSAGE_15: '조직폴더 반출 정책을 변경하였습니다',
	USER_MESSAGE_16: '계정 잠금을 변경하였습니다',
	USER_ERROR_MESSAGE_1: '관리자 계정은 삭제할 수 없습니다',
	USER_ERROR_MESSAGE_2: '삭제실패',
	USER_ERROR_MESSAGE_3: '아이디를 입력해주세요',
	USER_ERROR_MESSAGE_4: '이름을 입력해주세요',
	USER_ERROR_MESSAGE_5: '암호를 입력해주세요',
	USER_ERROR_MESSAGE_6: '암호(확인)을 입력해주세요',
	USER_ERROR_MESSAGE_7: '암호가 일치하지않습니다',
	USER_ERROR_MESSAGE_8: '이미 사용중이 아이디입니다',
	USER_ERROR_MESSAGE_9: '아이디는 30자리 이하로해주세요',
	USER_ERROR_MESSAGE_10: '사용자 라이센스가 부족합니다',
	USER_ERROR_MESSAGE_11: '이름은 30자리 이하로해주세요',
	USER_ERROR_MESSAGE_12: '사용자를 선택해주세요',
	USER_ERROR_MESSAGE_13: '제품 사용기간이 만료되었습니다',
	USER_ERROR_MESSAGE_14: '폴더를 선택해주세요',
	USER_ERROR_MESSAGE_16: '암호 재설정에 실패하였습니다',
	USER_ERROR_MESSAGE_17: '종이암호 생성 실패',
	USER_ERROR_MESSAGE_18: 'TOTP초기화에 실패하였습니다',
	USER_ERROR_MESSAGE_19: '사용 중인 사용자는 삭제할 수 없습니다',
	USER_ERROR_MESSAGE_20: '수동 등록 사용자를 선택해 주세요',
	PATTERN_ERROR_MESSAGE: "'\\', '\"', '/', ':', '?', '*', '<', '>', '|'는 사용할 수 없습니다",
	RESET_PASSWORD_TITLE: '의 암호를 변경합니다',
	RESET_PASSWORD_CONTENT: '암호를 재설정합니다. 재설정된 암호로 로그인 후 새로운 암호로 변경해야 합니다',
	RESET_PASSWORD_ERROR_MESSAGE_1: '신규 암호를 입력해주세요',
	RESET_PASSWORD_ERROR_MESSAGE_2: '신규 암호(확인)을 입력해주세요',
	RESET_PASSWORD_ERROR_MESSAGE_3: '암호가 일치하지않습니다',
	RESET_NEW_PASSWORD: '신규 암호',
	RESET_NEW_PASSWORD_CONFRIM: '신규 암호(확인)',
	PERM_MESSAGE_1: '권한수정완료',
	PERM_MESSAGE_2: '권한제거완료',
	PERM_ERROR_MESSAGE_1: '권한수정실패',
	PERM_ERROR_MESSAGE_2: '권한제거실패',
	PERM_ERROR_MESSAGE_3: '폴더를 선택해주세요',
	PERM_ERROR_MESSAGE_4: '중복되는 관리자 우선순위가 있습니다',
	SHARED_MESSAGE_1: '공유폴더 삭제 완료',
	ORG_MESSAGE_1: '조직 삭제 완료',
	ORG_ERROR_MESSAGE_1: '조직 삭제 실패',
	ORG_ERROR_MESSAGE_2: '조직을 선택해 주세요',
	SHARED_ERROR_MESSAGE_1: '공유폴더 삭제 실패',
	SHARED_ERROR_MESSAGE_2: '공유폴더 추가완료',
	FOLDER_ERROR_MESSAGE_1: '폴더명을 입력해주세요',
	FOLDER_ERROR_MESSAGE_2: '이미 사용중인 폴더명입니다',
	FOLDER_ERROR_MESSAGE_3: '30자리 이하만 입력 가능합니다',
	FOLDER_ERROR_MESSAGE_4: '최상위폴더는 권한추가가 안됩니다',
	FOLDER_ERROR_MESSAGE_5: '조직공유폴더는 등록한 사용자가 직접 해제해야합니다',
	RESTORE_TEMP: '임시복원',
	RESTORE_TEMP_PROCESSING: '임시복원 중',
	RESTORE_TEMP_FINISH: '임시복원 종료',
	RESTORE_TITLE: '복원을 시작하시겠습니까?',
	RESTORE_MESSAGE_1: '오늘 변경 된 내용을 무효화 합니다',
	RESTORE_MESSAGE_2: '임시복원을 종료하시겠습니까?',
	RESTORE_MESSAGE_3: "복원을 시작하시려면 '복원시작' 을 입력한 후 확인을 눌러주세요",
	RESTORE_MESSAGE_4: '선택한 시간 이후 내용을 무효화 합니다',
	RESTORE_MESSAGE_5: '이후 내용을 무효화 하시겟습니까?',
	RESTORE_MESSAGE_6: '임시복원 또는 복원 중 하나를 선택해 주세요',
	RESTORE_MESSAGE_7: '복원중에는 변경이 불가능합니다',
	RESTORE_MESSAGE_8: '임시복원 중입니다',
	RESTORE_MESSAGE_9: '무효화 할 시간',
	RESTORE_ERROR_MESSAGE_1: '확인문구를 입력해주세요',
	RESTORE_ERROR_MESSAGE_2: '확인문구가 다릅니다',
	RESTORE_ERROR_MESSAGE_3: '임시복원 모드가 정확하지 않습니다',
	RESTORE_ERROR_MESSAGE_4: '임시복원실행에 실패하였습니다',
	RESTORE_ERROR_MESSAGE_5: '임시복원종료에 실패하였습니다',
	RESTORE_ERROR_MESSAGE_6: '복원 모드가 정확하지 않습니다',
	RESTORE: '자료 복원',
	RESTORE_SUB_TITLE: '복원 시점',
	RESTORE_MODE: '모드',
	RESTORE_MODE_TODAY: '오늘',
	RESTORE_MODE_HOUR: '시간',
	RESTORE_MODE_DAY: '기간',
	RESTORE_HOUR: '복원 시간',
	RESTORE_NOW: '최근',
	RESTORE_HOUR_BEFORE: '시간 전',
	RESTORE_TODAY: '금일 변경 내용 무효화',
	RESTORE_ONE_TIME: '특정시간 이후 변경 사항 무효화',
	RESTORE_DAY: '기간설정',
	RESTORE_ONE_DAY: '특정일',
	RESTORE_TEMP_CHANGING: '임시복원 파일 전환 중',
	RESTORE_CHANGING: '전환 중',
	SECUREDOC: '보안문서',
	SECUREDOC_READFILE: '열람용문서',
	SECUREDOC_RELEASE_TITLE: '보안문서 해제',
	SECUREDOC_RELEASE_CONTENT: '보안문서를 해제하시겠습니까?',
	SECUREDOC_DOCID: '문서번호',
	SECUREDOC_TYPE: '문서종류',
	SECUREDOC_PATH: '문서',
	SECUREDOC_MESSAGE_1: '보안문서 접근사용자 정보를 수정하였습니다',
	SECUREDOC_MESSAGE_2: '보안문서 레벨을 수정하였습니다',
	SECUREDOC_MESSAGE_3: '보안문서 해제 완료',
	SECUREDOC_ERROR_MESSAGE_1: '보안문서 정보 수정에 실패하였습니다',
	SECUREDOC_ERROR_MESSAGE_2: '보안문서 해제에 실패',
	SERVER_SETTING: '기타',
	SETTING: '설정',
	SAVED: '저장되었습니다',
	FACTORY_RESET: '공장 초기화',
	RESET: '초기화',
	FACTORY_RESET_CONFIRM: '공장 초기화를 진행하시겠습니까?',
	FACTORY_RESET_MESSAGE: '공장 초기화 후 관리자 생성 화면으로 이동됩니다',
	FACTORY_RESET_FAILED: '공장 초기화 실패',
	DEFAULT_SETTING: '기본',
	WEBLINK_SETTING: '웹 링크',
	WEBLINK_USED: '웹 링크 서비스 사용',
	WEBLINK_SERVICE_PORT: '웹 링크 서비스 포트',
	WEBLINK_SERVICE_ADDR: '웹 링크 서비스 주소',
	FOLDER_SIZE_SETTING: '폴더별 용량',
	PRIVATEFOLDER_SIZE: '사용자 폴더 용량',
	COLLECTFOLDER_SIZE: '수집 폴더 용량',
	SHAREDFOLDER_SIZE: '공유 폴더 용량',
	ORGFOLDER_SIZE: '조직 폴더 용량',
	FOLDER_SIZE_HELP_TEXT: '0일 경우 무제한',
	COLLECT_GLOBAL_SETTING: '수집기',
	COLLECT_PRIVATE_SETTING: '사용자별 수집',
	COLLECT_USE: '문서 수집',
	COLLECT_USED: '문서 수집 적용',
	COLLECT_NOT_USE: '문서 수집 미적용',
	COLLECT_USE_FULL_SCAN: '기존 문서 수집',
	COLLECT_USE_FULL_SCAN_MANAGEMENT: '기존 문서 수집 관리',
	COLLECT_USED_FULL_SCAN: '기존 문서 수집 적용',
	COLLECT_NOT_USED_FULL_SCAN: '기존 문서 수집 미적용',
	COLLECT_USE_REMOVE_SOURCE: '문서 이관',
	COLLECT_USED_REMOVE_SOURCE: '문서 이관 적용',
	COLLECT_NOT_USED_REMOVE_SOURCE: '문서 이관 미적용',
	COLLECT_SYNC_REMOVE: '삭제 파일 동기화 사용',
	COLLECT_PATH_SETTING: '수집 경로',
	INCLUSIVE: '대상경로 방식',
	EXCLUSIVE: '제외경로 방식',
	COLLECT_EXCLUSIVE_EXT_SETTING: '수집 확장자',
	COLLECT_EXCLUSIVE_EXT_SETTING_HELP_TEXT:
		'확장자 지정 방식으로 설정한 확장자는 랜섬웨어 감지에 사용됩니다. 확장자 제외 방식의 경우 랜섬웨어 감지에 사용할 확장자를 별도로 지정해야 합니다',
	INCLUSIVE_EXT: '확장자 지정 방식',
	EXCLUSIVE_EXT: '확장자 제외 방식',
	COLLECT_EXCLUSIVE_EXT: '수집 제외 확장자',
	COLLECT_EXT: '수집 확장자',
	RANSOMWARE_EXT: '랜섬웨어 감지 확장자',
	COLLECT_IGNORE: '수집 제외 경로',
	COLLECT_INCLUSIVE: '수집 대상 경로',
	COLLECT_MESSAGE_1: '설정을 적용하였습니다',
	COLLECTOR_TRANSFER_SIZE: '수집 파일 업로드 크기',
	COLLECTOR_INTERVAL_TRANSFER: '수집 파일 전송 대기 시간',
	COLLECTOR_INTERVAL_TRANSFER_HELP_TEXT: '수집 파일 업로드 크기만큼 업로드 후 대기시간',
	COLLECT_ALL_USE: '전체 사용',
	COLLECT_ALL_UNUSED: '전체 미사용',
	COLLECT_ALL_APPLY: '전체 적용',
	COLLECT_ALL_UNAPPLIED: '전체 미적용',
	COLLECT_FULL_SCAN_STATUS: '수집 상태',
	COLLECT_FULL_SCAN_RESTART: '재수집',
	COLLECT_FULL_SCAN_PRESCANNED_FOLDERS: '수집대상 폴더',
	COLLECT_FULL_SCAN_PENDING_FOLDERS: '남은 폴더',
	COLLECT_FULL_SCAN_PENDING_FILES: '남은 파일',
	COLLECT_FULL_SCAN_REQUEST_AT: '재수집 요청일',
	COLLECT_FULL_PRESCAN_FINISHED_AT: '폴더 탐색 완료 시간',
	COLLECT_FULL_SCAN_FINISHED_AT: '탐색 완료 시간',
	COLLECT_FULL_SCAN_STATUS_NONE: '미적용',
	COLLECT_FULL_SCAN_STATUS_STARTED: '탐색중',
	COLLECT_FULL_SCAN_STATUS_PRESCANED: '수집중',
	COLLECT_FULL_SCAN_STATUS_FINISHED: '수집 완료',
	EXTERNAL_SETTING: '외부접속',
	EXTERNAL_USED: '외부접속 사용',
	EXTERNAL_SERVERNAME: '외부접속시 사용 서버명',
	EXTERNAL_MESSAGE: '외부접속 설정 완료',
	EXTERNAL_ERROR_MESSAGE_1: '영문/숫자만 가능합니다',
	EXTERNAL_ERROR_MESSAGE_2: '외부접속시 사용 서버명을 입력해주세요',
	EXTERNAL_ERROR_MESSAGE_3: '미사용시 서버명 변경이 불가능합니다',
	EXTERNAL_ERROR_MESSAGE_4: '외부접속 설정에 실패하였습니다',
	EXTERNAL_ERROR_MESSAGE_5: '이미사용중인 서버명입니다',
	EXTERNAL_ERROR_MESSAGE_6: '변경내용이 없습니다',
	SETTING_BACKUP_KEEPDAY: '개정 내역 파일 보관 일수',
	SETTING_DEFAULT_VOLUME_LABEL: '연결 드라이브 이름',
	SETTING_SAMBA_PORT_USE: 'SAMBA 기본포트 사용',
	SETTING_SAMBA_PORT_USE_HINT: '445포트의 사용 여부. ‘로컬 파일 공유’를 사용하는 클라이언트가 있는 경우 활성화 해야 합니다',
	SETTING_TWO_FACTOR_AUTH: '2차 인증 강제 사용',
	SETTING_DISABLE_TWO_FACTOR_AUTH: '2차 인증 비활성화',
	SETTING_NO_SHELL_MENU: 'Context menu 숨김',
	SETTING_STRONG_PASSWORD: '강력한 로그인 암호 사용',
	SETTING_STRONG_PASSWORD_HINT:
		'사용자가 단순한 암호를 사용하지 못합니다. 최소 하나의 대문자, 소문자, 숫자, 특수문자를 각각 포함하는 최소 8자리의 암호를 생성 해야한다',
	SETTING_UPDATE: '업데이트',
	SETTING_UPDATE_MESSAGE_1: '업데이트파일을 등록하였습니다. 서버파일의 경우 서버 재시작 후 적용됩니다',
	SETTING_UPDATE_ERROR_MESSAGE_1: '정상적인 업로드 파일이 아닙니다',
	SETTING_UPDATE_ERROR_MESSAGE_2: '현재 버전보다 하위 버전입니다',
	SETTING_UPDATE_ERROR_MESSAGE_3: '업데이트에 실패하였습니다',
	SETTING_PII_DETECT: '개인정보 검출',
	SETTING_PII_DETECT_ENABLE: '개인정보 검출 사용',
	SETTING_PII_DETECT_OCR: '이미지 파일에 대해 개인정보 검출',
	SETTING_DUP_DETECT_ENABLE: '중복 문서 관리 사용',
	SETTING_RANSOMWARE_DETECT_USE: '랜섬웨어 감지',
	SETTING_SECURE_DOCUMENT_USE: '보안문서',
	SETTING_MIN_SMB_VERSION: '접속 가능한 SMB 프로토콜 최소 버전',
	SETTING_SMB_ENCRYPTION: 'SMB 패킷 암호화 사용',
	SETTING_SMB_ENCRYPTION_HELP_TEXT:
		'SMB 패킷을 암호화하여 송수신합니다. 외부에서 VPN 등 보안이 강화된 네트워크가 아닌 환경에서 데이터 유출을 방지할 수 있으며 네트워크 통신 속도가 약간 감소합니다',
	DOWNLOAD: '다운로드',
	DOWNLOAD_CLIENT: '클라이언트 다운로드',
	EXPIRE_AT: '만료일',
	LICENSES_TITLE: '라이센스 정보',
	USER_LICENSES: '최대 사용자수',
	MAINTENANCE_AT: '유지보수 기한',
	PRODUCTKEY_STATE: '제품키 상태',
	INVALID: '비정상',
	VALID: '정상',
	SEEDKEY: '시드키 확인',
	COPY: '복사',
	SEEDKEY_COPY: '시드키 복사',
	WEBLINK_ERROR_MESSAGE_1: '다운로드 가능 횟수를 초과하였습니다',
	WEBLINK_ERROR_MESSAGE_2: '다운로드 링크가 활성화 되어있지 않습니다',
	WEBLINK_ERROR_MESSAGE_3: '다운로드 가능 기간이 지났습니다',
	WEBLINK_ERROR_MESSAGE_4: '다운로드 가능 정보가 잘못되었습니다. URL을 확인해주세요.',
	WEBLINK_ERROR_MESSAGE_5: '암호를 입력해주세요',
	WEBLINK_ERROR_MESSAGE_6: '암호(확인)을 입력해주세요',
	WEBLINK_ERROR_MESSAGE_7: '암호가 일치하지 않습니다',
	WEBLINK_ERROR_MESSAGE_8: '다운로드 횟수 제한을 입력해주세요',
	WEBLINK_ERROR_MESSAGE_9: '다운로드 횟수를 숫자로 입력해주세요',
	WEBLINK_ERROR_MESSAGE_10: '웹 링크를 만들 파일을 찰을 수 없습니다',
	NAME_CHANGE: '이름 변경',
	ORG_CHANGE_MESSAGE: '조직명을 변경하시겠습니까?',
	SHARED_CHANGE_MESSAGE: '공유폴더명을 변경하시겠습니까?',
	TRANSFER: '이관',
	MOVE_ORG_DATA_TITLE: '데이터 이관',
	SOURCE_ORG_NAME: '원본 조직폴더',
	TARGET_ORG_NAME: '대상 조직폴더',
	MOVE_ORG_DATA_MESSAGE_1: '조직폴더 데이터를 이관하시겠습니까?',
	MOVE_ORG_DATA_MESSAGE_2: '이관을 완료하였습니다',
	MOVE_ORG_DATA_ERROR_MESSAGE_1: '원본 조직과 대상조직이 같습니다',
	MOVE_ORG_DATA_ERROR_MESSAGE_2: '이관에 실패하였습니다',
	RESTRICT_TITLE: '저장 제한 파일',
	RESTRICT_SIZE_TITLE: '용량 제한',
	RESTRICT_EXT_TITLE: '확장자 제한',
	RESTRICT_EXT_HELP_TEXT: '","로 구분하여 여러 확장자를 지정할 수 있다(대소문자 구분 안 함)',
	RESTRICT_EXT_REMOVE: '확장자 제한 파일 저장 금지',
	RESTRICT_EXT_REMOVE_HELP_TEXT:
		'확장자 제한 파일의 저장이 즉시 오류로 반환됩니다. 미사용시 제한 파일을 일시적으로 저장 후 일일 배치 작업에 의해 삭제됩니다',
	CURRENT_PASSWORD: '현재 암호',
	NEW_PASSWORD: '신규 암호',
	CHANGE_PASSWORD: '암호 변경',
	SHUTDOWN: '종료',
	SYSTEM_SHUTDOWN: '시스템 종료',
	SHUTDOWN_MESSAGE_1: '시스템을 종료하시겠습니까?',
	SHUTDOWN_MESSAGE_2: '정상 종료 되었습니다',
	NO_USED: '미사용',
	USAGE: '사용량',
	GROUP_USAGE: '그룹별 사용량',
	REMAINING_CAPACITY: '남은 용량',
	TOTAL_CAPACITY: '전체 용량',
	ALL: '전체',
	DETAIL: '상세',
	ACCESS_TYPE: '파일 로그 종류',
	ACCESS_TYPE_CREATE: '생성',
	ACCESS_TYPE_READ: '읽기',
	ACCESS_TYPE_MOVE: '이동',
	ACCESS_TYPE_UPDATE: '변경',
	ACCESS_TYPE_HOLD: '사용',
	ACCESS_TYPE_REMOVE: '삭제',
	ACCESS_TYPE_REMOVE_TRASH: '휴지통',
	ACCESS_TYPE_RECOVERY_TRASH: '휴지통 복원',
	ACCESS_TYPE_PURGE_TRASH: '영구 삭제',
	ACCESS_TYPE_VIEWING: '열람',
	ACCESS_TYPE_WEB_CREATE: '업로드',
	ACCESS_TYPE_WEB_DOWNLOAD: '다운로드',
	ACCESS_TYPE_COLLECT_FILE: '수집',
	TODAY_AVERAGE_DATA: '실시간 평균 사용량',
	FILE_LOCATION: '파일 위치',
	REGIST_DATE: '등록일',
	REGIST_USER: '등록자',
	RELEASE: '해제',
	SECUREDOC_DIALOG_CONTENTS_1: '이 보안문서에 접근하는데 필요한 최소 등급',
	SECUREDOC_DIALOG_CONTENTS_2: '이 보안문서에 접근할 수 있는 사용자 리스트',
	SECUREDOC_DIALOG_CONTENTS_3: '이 문서에 접근가능한 특정 사용자가 없습니다',
	SECUREDOC_DIALOG_MESSAGE_1: '보안등급을 낮출 권한이 없습니다',
	SECUREDOC_DIALOG_MESSAGE_2: '해제되었습니다',
	SECUREDOC_DIALOG_MESSAGE_3: '변경되었습니다',
	SECUREDOC_DIALOG_MESSAGE_4: '등록되었습니다',
	DELETE_PERMANENTLY: '영구 삭제',
	NEW_FOLDER: '새 폴더',
	UPLOAD: '업로드',
	FILE_DOWNLOAD: '내려 받기',
	PASTE: '붙여넣기',
	SEARCH: '검색',
	CUT: '잘라내기',
	REVISION_HISTORY: '개정 내역',
	SEARCH_IN_FOLDER: '폴더 내 검색',
	SEARCH_DELETED_FILE_IN_FOLDER: '삭제된 파일 검색',
	RENAME: '이름 변경',
	CONTEXT_MESSAGE_1: '특수 폴더 최상위에는 업로드 할 수 없습니다',
	CONTEXT_MESSAGE_2: '지정한 이름을 가진 폴더가 이미 있습니다',
	CONTEXT_MESSAGE_3: '이름이 같은 파일이 있습니다',
	CONTEXT_MESSAGE_4: '권한이 없습니다',
	CONTEXT_MESSAGE_5: '생성에 실패하였습니다',
	CONTEXT_MESSAGE_6: '한 개의 파일만 선택해 주세요',
	CHANGE_PASSWORD_MESSAGE_1: '암호를 변경하였습니다',
	CHANGE_PASSWORD_ERROR_MESSAGE_1: '신규암호가 일치하지 않습니다',
	CHANGE_PASSWORD_ERROR_MESSAGE_2: '신규암호가 이전 암호와 동일합니다',
	CHANGE_PASSWORD_ERROR_MESSAGE_3: '현재암호가 일치하지 않습니다',
	CHANGE_PASSWORD_ERROR_MESSAGE_4:
		'암호는 최소한 하나의 대문자, 하나의 소문자, 하나의 숫자 및 하나의 특수문자를 포함해야합니다',
	FILE_NAME: '파일명',
	FILE_SIZE: '파일크기',
	FILE_MODIFIED: '마지막 수정일',
	REVERT: '되돌리기',
	REVISION_DELETE: '개정본 삭제',
	REVISION_EMPTY: '개정본이 존재하지 않습니다',
	REVISION_DIALOG_MESSAGE_1: '개정본을 선택해주세요',
	REVISION_DIALOG_MESSAGE_2: '현재 파일이 지정된 개정본으로 변경되었습니다',
	REVISION_DIALOG_MESSAGE_3: '현재 파일을 선택된 개정본으로 되돌리겠습니까?',
	REVISION_DIALOG_MESSAGE_4: '선택된 개정본을 삭제하시겠습니까?',
	SEARCH_ALL: '전체검색',
	SEARCH_BELOW: '아래에서 검색',
	SEARCH_STRING: '검색어',
	SEARCH_EXCLUSION_STRING: '검색 제외 문자열',
	SEARCH_EXTS: "확장자(콤마 ','로 구분)",
	OPTION: '옵션',
	PREFIX: '접두어',
	LIKE: '포함',
	DELETE_DATE: '삭제일',
	DELETE_USER: '삭제자',
	DELETE_USER_ID: '사용자 아이디',
	DELETE_USER_NAME: '사용자 이름',
	SEARCH_DIALOG_MESSAGE_1: '파일을 선택해 주세요',
	RESTORE_COMPLETE: '복원되었습니다',
	DELETE_COMPLETE: '삭제되었습니다',
	MAX_CAPACITY: '용량제한',
	PRIVATE_MAX_CAPACITY: '개인폴더 용량제한',
	COLLECT_MAX_CAPACITY: '수집폴더 용량제한',
	COLLECT_DRIVE_FIXED: '고정형 디스크 수집 여부',
	COLLECT_DRIVE_REMOVABLE: '이동형 디스크 수집 여부',
	COLLECT_DRIVE_CDROM: 'CDROM 드라이브 수집 여부',
	COLLECT_DRIVE_RAMDISK: 'RAM 드라이브 수집 여부',
	COLLECT_DRIVE_REMOTE: '네트워크 연결 드라이브 수집 여부',
	COLLECT_DRIVE_FIXED_HOTPLUG: 'USB연결 고정형 디스크 수집 여부',
	CHANGE_CAPACITY_MESSAGE_1: '개인폴더 용량제한을 변경하였습니다',
	CHANGE_CAPACITY_MESSAGE_2: '수집폴더 용량제한을 변경하였습니다',
	CHANGE_CAPACITY_MESSAGE_3: '용량제한을 변경하였습니다',
	CHANGE_CAPACITY_MESSAGE_4: '변경 사항이 반영 중입니다.',
	CHANGE_CAPACITY_ERROR_MESSAGE_1: '용량제한을 변경에 실패하였습니다',
	PII_DOCUMENTS: '개인정보 검출',
	PII_NAME: '검출 대상',
	PII_ELEMENT: '검출 요소',
	DUP_DOCUMENTS: '중복 문서',
	DUP_FILE_HASH: '파일 해시',
	DUP_FILE_PATH: '파일 위치',
	WHITE_PROCESS: '보안 프로세스',
	PROCESS: '프로세스',
	URL: 'URL',
	URL_REGISTRATION: 'URL 등록',
	PROCESS_DELETE_TITLE: '프로세스 제거',
	URL_DELETE_TITLE: 'URL 제거',
	PROCESS_DELETE_CONTENT: '%s 프로세스를 제거하시겠습니까?',
	URL_DELETE_CONTENT: 'URL(%s)를 제거하시겠습니까?',
	PROCESS_APPROVE_TITLE: '프로세스 승인',
	PROCESS_APPROVE_CONTENT: '프로세스를 %s 하시겠습니까?',
	WHITE_URL_MODAL_1: 'URL 등록',
	WHITE_URL_MODAL_2: 'Chrome Browser를 통해 파일을 업로드할 수 있는 URL을 지정합니다',
	WHITE_PROCESS_ID: '아이디',
	WHITE_PROCESS_VERIFY_TYPE: '검증 방법',
	WHITE_PROCESS_PROGRAM_NAME: '프로그램 명',
	WHITE_PROCESS_VERIFY_HASH: '파일 해시',
	WHITE_PROCESS_VERIFY_DIGITAL: '디지털 인증',
	WHITE_PROCESS_MESSAGE_1: '프로세스를 제거하였습니다',
	WHITE_PROCESS_MESSAGE_2: 'URL을 제거하였습니다',
	WHITE_PROCESS_MESSAGE_3: 'URL을 등록하였습니다',
	WHITE_PROCESS_MESSAGE_4: '“http://” 또는 “https://”는 생략하고 입력해 주세요',
	WHITE_PROCESS_ERROR_MESSAGE_1: '프로세스 제거에 실패하였습니다',
	WHITE_PROCESS_ERROR_MESSAGE_2: 'URL 제거에 실패하였습니다',
	WHITE_PROCESS_ERROR_MESSAGE_3: '명칭을 입력해 주세요',
	WHITE_PROCESS_ERROR_MESSAGE_4: 'URL을 입력해 주세요',
	WHITE_PROCESS_ERROR_MESSAGE_5: 'URL을 등록에 실패하였습니다',
	SETTING_CENTRALIZED_DOC: '문서중앙화',
	ACCESS_CONTROL_USED: '접근제어 기능 사용',
	SAVING_CONTROL_USED: '저장제어 기능 사용',
	TRANSFER_ORG: '조직폴더 이동',
	TRANSFER_ORG_MESSAGE_1: '조직폴더를 이동하시겠습니까?',
	TRANSFER_ORG_MESSAGE_2: '이동 완료',
	TRANSFER_ORG_ERROR_MESSAGE_1: '이동 될 상위폴더를 선택해 주세요.',
	TRANSFER_ORG_ERROR_MESSAGE_2: '이동에 실패하였습니다',
	REMOVE_FILE_ERROR_MESSAGE_1: '삭제에 실패하였습니다. 권한을 확인해주세요',
	FILE_LOG_TITLE: '파일 로그',
	AUDIT_LOG_TITLE: '감사 로그',
	DOCUMENT: '문서',
	SETUP_SETTING: '클라이언트',
	CONNECT_SERVER_ADDRESS: '서버 주소',
	CONNECT_SERVER_PORT: '서버 포트',
	MAP_MOUNT_DRIVE: '기본 연결 드라이브 문자',
	FORCE_MAP_MOUNT_DRIVE: '연결 드라이브 문자 잠금',
	FORCE_MAP_MOUNT_DRIVE_HINT: '사용자가 연결 드라이브 문자를 변경할 수 없습니다',
	SILENT_SETUP: '업데이트시 자동 결정',
	SILENT_SETUP_HINT: '업데이트 중 사용자 결정 없이 자동으로 진행한다',
	VERYSILENT_SETUP: '설치 화면 숨김',
	DISABLE_CONNECT_BUTTON_WEBADMIN: '관리자 페이지 접속 버튼 숨김 ',
	AUTO_START: '업데이트 후 클라이언트 자동 시작',
	DESKTOP_WEB_SUPPORT: '웹 클라이언트 접속',
	MOBILE_WEB_SUPPORT: '모바일 클라이언트 접속',
	INTEGRATED_WITH_AD: '클라이언트 AD 연동',
	COLLECT_INCLUSIVE_NAME: '수집 폴더명',
	RANSOM_MANAGEMENT: '랜섬웨어 탐지',
	RANSOMWARE_PATH: '랜섬웨어 프로세스 경로',
	RANSOMWARE_STATUS: '처리 내역',
	CLIENT_ADDRESS: '클라이언트 IP',
	INFECTED_FILES: '감염 파일',
	STATUS: '상태',
	DETECTED_AT: '탐지일',
	START_DATE: '시작일',
	END_DATE: '종료일',
	AUDIT_TYPE_ALL: '전체',
	AUDIT_TYPE_LOGGED_IN: '로그인',
	AUDIT_TYPE_LOGGED_OFF: '로그 아웃',
	AUDIT_TYPE_CREATE_USER: '사용자 생성',
	AUDIT_TYPE_MODIFY_USER: '사용자 변경',
	AUDIT_TYPE_MODIFY_PASSWORD: '암호 변경',
	AUDIT_TYPE_REMOVE_USER: '사용자 삭제',
	AUDIT_TYPE_CREATE_PAPER_PASSWORD: '종이암호 생성',
	AUDIT_TYPE_CREATE_ORGANIZATION: '조직 생성',
	AUDIT_TYPE_RENAME_ORGANIZATION: '조직명 변경',
	AUDIT_TYPE_TRANSFER_ORGANIZATION: '조직 이관',
	AUDIT_TYPE_MOVE_ORGANIZATION_DATA: '조직 자료 이관',
	AUDIT_TYPE_REMOVE_ORGANIZATION: '조직 삭제',
	AUDIT_TYPE_CREATE_SHARED_FOLDER: '공유폴더 생성',
	AUDIT_TYPE_RENAME_SHARED_FOLDER: '공유폴더명 변경',
	AUDIT_TYPE_REMOVE_SHARED_FOLDER: '공유폴더 삭제',
	AUDIT_TYPE_ADD_FOLDER_PERMISSION: '폴더 권한 추가',
	AUDIT_TYPE_UPDATE_FOLDER_PERMISSION: '폴더 권한 갱신',
	AUDIT_TYPE_REMOVE_FOLDER_PERMISSION: '폴더 권한 삭제',
	AUDIT_TYPE_CREATE_ORGSHARED_FOLDER: '조직공유폴더 생성',
	AUDIT_TYPE_REMOVE_ORGSHARED_FOLDER: '조직공유폴더 삭제',
	AUDIT_TYPE_RESET_FOLDER_PERMISSION: '폴더 권한 초기화',
	AUDIT_TYPE_REGISTER_SECURED_PROCESS: '보안 프로세스 등록',
	AUDIT_TYPE_UNREGISTER_SECURED_PROCESS: '보안 프로세스 등록 해제',
	AUDIT_TYPE_SYSCONFIG_CHANGED: 'SysConfig 변경',
	AUDIT_TYPE_CREATE_SUB_USER: '사용자 생성(중복로그인)',
	DIVISION: '구분',
	EXISTING_FILE: '기존 파일',
	NEW_FILE: '신규 파일(또는 실행 프로그램)',
	DATE: '날짜',
	MORE_INFO: '추가 정보',
	PREVIOUS_INFO: '기존 정보',
	CHANGED_INFO: '변경된 정보',
	AUDIT_TYPE: '감사 로그 종류',
	NOW_ACCESSLOG: '실시간 사용 내역',
	DASHBOARD: '대시보드',
	NOW_FILE_ACCESSLOG: '파일 로그',
	DESKTOP_WEB_NOT_SUPPORT: '사용자 페이지는 지원하지 않습니다',
	PII_SEARCH: '파일명 / 폴더명',
	MANAGE_TYPE_NONE: '일반사용자',
	MANAGE_PRIORITY: '관리자 우선순위',
	OSR_ADD_ORG: '추가될 조직',
	OSR_REMOVE_ORG: '삭제될 조직',
	OSR_REMOVE_USER: '삭제될 사용자',
	OSR_NO_CHANGE: '유지',
	OSR: '인사 연동',
	OSR_CONFIRM_MESSAGE_1: '인사 연동을 적용하시겠습니까?',
	OSR_CONFIRM_MESSAGE_2: '인사 연동을 적용하였습니다',
	OSR_CONFIRM_MESSAGE_3: '조직코드를 등록하시겠습니까?',
	DATALEAK_CONTRL: '문서 반출 방지 사용',
	DATALEAK_MESSAGE_1: '문서 유출 방지 기능을 사용할 수 없습니다',
	DATALEAK_MESSAGE_2: '접근제어 기능을 사용할 수 없습니다',
	DATALEAK_MESSAGE_3: '저장제어 기능을 사용할 수 없습니다',
	TAKEOUT_TITLE: '반출 문서',
	TAKEOUT_REQ_AT: '요청일',
	TAKEOUT_APP_AT: '처리일',
	TAKEOUT_REQUESTER: '요청자',
	TAKEOUT_APPROVER: '승인자',
	TAKEOUT_REQ_TYPE: '요청 타입',
	TAKEOUT_STATUS: '상태',
	TAKEOUT_REASON_REQ: '요청 사유',
	TAKEOUT_REASON_REJ: '반려 사유',
	TAKEOUT_FILE_COUNT: '문서 개수',
	TAKEOUT_REQUEST: '요청',
	TAKEOUT_APPROVED: '승인',
	TAKEOUT_APPROVED_AUTO: '자동 승인',
	TAKEOUT_REJECTED: '거절',
	TAKEOUT_WITHDRAWN: '요청 철회',
	TAKEOUT_DOC: '문서 반출',
	TAKEOUT_PRINT: '프린트 반출',
	TAKEOUT_WEBLINK: '웹 링크 반출',
	LOCAL_ACCOUNT: '수동 등록 사용자',
	LOCAL_ACCOUNT_HELP: "※인사DB에 있는 계정은 '아니오'를 선택해주세요. 중복시 인사연동에 실패합니다",
	ALLOW_ADMIN_ACCESS_USERS_FOLDERS: '관리자 계정으로 클라이언트 로그인 가능',
	ALLOW_ADMIN_ACCESS_USERS_FOLDERS_HINT: '클라이언트에서 관리자 계정으로 로그인 가능해집니다',
	FORCE_SETUP_PERFORM: '비호환 클라이언트 강제 업데이트',
	FORCE_SETUP_PERFORM_HINT: '클라이언트 버전이 서버와 호환되지 않는 경우 강제로 새로운 클라이언트로 업데이트합니다',
	HOLD_FILES: '사용 문서',
	SINCE_SECE: '사용 시간',
	START_TIME: '시작 시간',
	AUTO_DETER: '자동 결정',
	USER_DETER: '사용자 결정',
	HIDDEN: '숨김',
	VISIBLE: '노출',
	AUTO_START_AFTER_BOOT: '부팅 후 자동 시작',
	NOT_USED: '사용 안 함',
	FORCED_UPDATE: '강제 업데이트',
	USER_CHOICE: '사용자 선택',
	OSR_REMOVE_USER_LABEL: '인수자 (미지정시 유지)',
	RANSOMWARE_STATUS_NONE: '전체',
	RANSOMWARE_STATUS_COM: '랜섬웨어 아님',
	RANSOMWARE_STATUS_BLOCK: 'I/O 차단',
	CHANGE_CAPACYTI_TITLE: '의 용량 변경',
	SETTING_LOCK_VOLUME_LABEL: '연결 드라이브 이름 잠금',
	RANSOMWARE_FILE_STATUS_COM: '랜섬웨어 아님',
	RANSOMWARE_FILE_STATUS_RESTORE: '복구된 파일',
	RANSOMWARE_FILE_STATUS_INFECTION: '감염 파일',
	CLEARANCE: '정리',
	OSR_BTN_1: '직접 설정',
	OSR_BTN_2: '같은 조직으로 설정',
	OSR_BTN_3: '모두 정리',
	OSR_BTN_4: '같은 사용자로 설정',
	OSR_HINT_MESSAGE_1:
		"인사 DB에 추가된 조직 목록입니다. 조직 경로 또는 조직명이 변경된 경우 '변경 전 조직명'을 선택해 주세요. 신규 생성 조직은 '추가'를 선택하시면 됩니다",
	OSR_HINT_MESSAGE_2:
		"인사 DB에 없는 조직 목록입니다. 쿠독 서비스에서 계속 사용할 경우 '유지'를 사용하지 않을 경우 '정리'를 선택하시면 됩니다. 만약 다른 부서로 데이터 이관 시에는 '이관을 원하는 조직'을 선택해 주세요",
	OSR_HINT_MESSAGE_3:
		"인사 DB에 없는 사용자 목록입니다. 쿠독 서비스에서 사용자 정보가 필요한 경우 '유지'를 필요 없는 경우 '정리'를 선택하시면 됩니다. 만약 다른 사용자로 데이터 이관 시에는 '이관을 원하는 사용자'를 선택해 주세요",
	OSR_ERROR_MESSAGE_1: '추가 될 조직을 먼저 설정해 주세요',
	OSR_ERROR_MESSAGE_2: '상위 조직폴더를 삭제 또는 이관할 경우 하위 조직폴더를 유지할 수 없습니다',
	OSR_ERROR_MESSAGE_3: '인사 정보 조회 중 문제가 발생하였습니다. 담당자에게 문의해주세요',
	OSR_ERROR_MESSAGE_4: '인사연동 진행 중입니다',
	OSR_ERROR_MESSAGE_5: '인사연동 중 장애가 발생하였습니다. 담당자에게 문의해주세요',
	OSR_ERROR_MESSAGE_6: '이미 등록된 조직코드입니다.',
	SYNC_SOLUTION_TITLE: '동기화 솔루션',
	SYNC_SOLUTION_INTEGRATED: '동기화 솔루션 사용',
	SYNC_SOLUTION_INTEGRATED_HINT_1: '문서 수집기가 수집 대상 파일들을 서버로 수집하지 않고 동기화 폴더로 수집합니다',
	SYNC_SOLUTION_INTEGRATED_HINT_2:
		'파일이 수집되는 최종 목적지 이외는 기존 수집기의 모든 기능(수집 대상, 수집 제외 대상, 복사 또는 이관 등등)이 작동한다',
	SYNC_SOLUTION_NAME: '동기화 솔루션 명',
	SYNC_SOLUTION_CONSUMER_FOLDER_ACCESSIBLE: '개인버전 동기화 솔루션 사용',
	NO_SHELL_MENU: 'Context 메뉴 숨김',
	NO_TRAY_ICON_MENU: '트레이 아이콘 메뉴 미사용',
	NO_TRAY_ICON_MENU_HINT: '미사용시 COODOC Tray Icon를 더블 클릭 했을 경우 Dashboard도 팝업되지 않습니다',
	SETTING_SUPPORT_OPLOCKS: 'Opportunistic Locks 사용',
	SETTING_SUPPORT_OPLOCKS_HELP_TEXT:
		'Windows OS의 Opportunistic Locks의 지원 여부, Excel의 공동작업을 여러 사용자가 접근하는 것이 실패할 경우 이 옵션을 미사용 해야합니다',
	SEARCH_INDEXING: '검색 인덱싱',
	SEARCH_PRIVATE_INDEXING: '개인폴더 인덱싱',
	SEARCH_COLLECT_INDEXING: '수집폴더 인덱싱',
	SEARCH_INDEXING_MESSAGE_1: '인덱싱 처리 목록에 추가하였습니다',
	REFRESH: '새로고침',
	EXCEL_DOWNLOAD: '엑셀 다운로드',
	FILE_LOG_EXCEL: '파일로그_',
	AUDIT_LOG_EXCEL: '감사로그_',
	WEBLINK: '웹 링크 생성',
	SYNC_OSR: '인사연동',
	NO_SYNC_OSR: '인사연동 제외',
	NO_SYNC_TARGET: '제외 대상',
	EXCLUSION: '제외',
	DOWNLOAD_LIMIT: '다운로드 횟수 제한',
	EXPIRE_DATE: '만료일',
	SEARCH_KEYWORD: '검색어 입력',
	ENTER_ID: '아이디를 입력해주세요',
	ENTER_NAME: '이름을 입력해주세요',
	ENTER_EMAIL: '이메일을 입력해주세요',
	ENTER_PASSWORD: '암호를 입력해주세요',
	ENTER_PASSWORD_CONF: '암호를 한번 더 입력해주세요',
	TOTAL: '총',
	CNT: '건',
	NEW_REGISTRATION: '신규 등록',
	DELETE_SELECTION: '선택 삭제',
	ROW_PER_PAGE_LABEL: '페이지당 행수',
	PAGE_PREV_BTN_LABEL: '이전 페이지',
	PAGE_NEXT_BTN_LABEL: '다음 페이지',
	HOLD_FILES_CONF_TITLE: '사용중인 문서 해제',
	HOLD_FILES_CONF_CONTENT: '의 세션을 종료합니다',
	HOLD_FILES_MESSAGE_1: '세션을 종료하였습니다',
	DETAIL_INFO: '상세정보',
	AVERAGE_READ: '읽기',
	AVERAGE_WRITE: '쓰기',
	AVERAGE_CONNECTED: '접속자 수',
	AVERAGE_FILE_TITLE_1: '파일 생성 / 삭제',
	AVERAGE_FILE_CREATE: '파일 생성',
	AVERAGE_FILE_REMOVE: '파일 삭제',
	AVERAGE_FILE_TITLE_2: '파일 변경',
	AVERAGE_FILE_RENAME: '이동(이름변경)',
	AVERAGE_FILE_UPDATE: '파일 변경',
	AVERAGE_FILE_COPY: '파일 복사',
	AVERAGE_FOLDER_TITLE: '폴더 생성 / 삭제',
	AVERAGE_FOLDER_CREATE: '폴더 생성',
	AVERAGE_FOLDER_REMOVE: '폴더 삭제',
	AVERAGE_CALL_API_TITLE: '웹 API 호출',
	UPDATE_NEW_CLIENT_LOCK: '버전 잠금',
	UPDATE_NEW_CLIENT_UNLOCK: '버전 잠금 해제',
	UPDATE_NEW_CLIENT_ALL_LOCK: '전체 버전 잠금',
	UPDATE_NEW_CLIENT_ALL_UNLOCK: '전체 버전 잠금 해제',
	CLIENT_INFO_VERSION: '클라이언트 버전',
	CLIENT_INFO_INSTALL_AT: '설치일',
	CLIENT_INFO_IP: '클라이언트 아이피',
	CLIENT_INFO_LAST_LOGIN_AT: '마지막 로그인',
	UPDATE_NEW_CLIENT_MESSAGE_1: '사용자(%d명) 버전을 잠그시겠습니까?',
	UPDATE_NEW_CLIENT_MESSAGE_2: '사용자(%d명) 버전 잠금을 해제하시겠습니까?',
	UPDATE_NEW_CLIENT_MESSAGE_3: '전체 사용자의 버전을 잠그시겠습니까?',
	UPDATE_NEW_CLIENT_MESSAGE_4: '전체 사용자의 버전 잠금을 해제하시겠습니까?',
	UPDATE_NEW_CLIENT_MESSAGE_5: '사용자 또는 조직을 선택해 주세요',
	UPDATE_NEW_CLIENT_MESSAGE_6: '조직(%d)의 버전을 잠그시겠습니까?',
	UPDATE_NEW_CLIENT_MESSAGE_7: '조직(%d)의 버전 잠금을 해제하시겠습니까??',
	LOCK: '잠금',
	UNLOCK: '해제',
	WHITE_URL_NAME: '명칭',
	WHITE_URL_NAME_PLACEHOLDER: '명칭을 입력해주세요',
	WHITE_URL_SET_TYPE: '비교방식',
	WHITE_URL_URL: 'URL',
	WHITE_URL_URL_PLACEHOLDER: 'URL을 입력해주세요',
	WHITE_URL_REGEX: '정규식',
	WHITE_URL_PLAIN: 'URL 일치',
	WHITE_PROGRAM: '보안프로그램',
	WHITE_SYSTEM_PROCESS: '시스템프로그램',
	WHITE_CHROME_BROWSER: '크롬 브라우저',
	WHITE_RANSOMWARE: '랜섬웨어 아님',
	WHITE_PROCESS_TYPE: '구분',
	TAKEOUT_APPROVE_TYPE_MANUAL: '수동 승인',
	TAKEOUT_APPROVE_TYPE_AUTO: '자동 승인',
	TAKEOUT_APPROVE_TYPE_UNAVAILABLE: '승인 불허',
	TAKEOUT_APPROVE_MESSAGE_1: '수정 완료',
	TAKEOUT_APPROVE_MANAGE_LOCK: '사용자 변경 잠금',
	TAKEOUT_APPROVE_MANAGE_LOCK_ALL: '모든 조직 잠금',
	TAKEOUT_APPROVE_MANAGE_UNLOCK_ALL: '모든 잠금 해재',
	EXPORT_APPROVAL_MANAGEMENT: '반출 승인자 관리',
	MANUAL: '수동',
	AUTO: '자동',
	INSTANT_LOGS_TITLE: '검색 설정',
	INSTANT_LOGS_FILE_TYPES: '로그파일 종류',
	INSTANT_LOGS_LOG_TYPES: '로그 종류',
	INSTANT_LOGS_INCLUDES: '포함 문자열',
	INSTANT_LOGS_EXCLUDES: '제외 문자열',
	INSTANT_LOGS_OMIT_SERVER: '서버 로그 수집',
	INSTANT_LOGS_CONTENTS: '메세지',
	INSTANT_LOGS_ERROR_MESSAGE_1: '검색후 설정해 주세요',
	WEBLINK_COPY_MESSAGE: '복사하였습니다',
	CLIENT_RUNNING_TYPE_START: '실행',
	CLIENT_RUNNING_TYPE_STOP: '종료',
	CLIENT_RUNNING_TYPE_IGNORE: '파일 미종료',
	CLIENT_RUNNING_TYPE_LOGOFF: 'PC종료',
	CLIENT_RUNNING_TYPE_ABORTED: '비정상 종료',
	DISABLE_SAVE_PASSWORD: '비밀번호 저장 비활성화',
	PRIVATE_FOLDER_SUPPORT: '개인폴더 사용',
	PRESERVE_LAST: '마지막 개정내역 보관',
	PRESERVE_LAST_WITHOUT_ORIGINAL: '휴지통 보관일 분리',
	PRESERVE_LAST_WITHOUT_ORIGINAL_HELP: "비활성화시 휴지통 보관일수는 '개정 내역 파일 보관 일수'과 동일",
	MAX_DAYS_PRESERVE_LAST_WITHOUT_ORIGINAL: '휴지통 보관일수',
	DOWNLOAD_ERROR: 'os를 선택해주세요.',
	NUMBER_ERROR_MESSAGE: '0보다 큰수를 입력해주세요.',
	DLP_CLIENT_INFO: 'dlp 클라이언트 정보',
	CONNECTED: '연결',
	NOT_CONNECTED: '미연결',
	DLP_POLICY_SETTING: 'dlp 정책 설정',
	DLPClientLogGeneral: '일반',
	DLPClientLogCreateFile: '생성',
	DLPClientLogWriteFile: '쓰기',
	DLPClientLogRenameFile: '이름변경',
	DLPClientLogCopyFile: '복사',
	DLPClientLogCloneFile: '복제',
	DLPClientLogLinkFile: '링크파일',
	DLPClientLogDeleteFile: '삭제',
	DLPClientLogTruncateFile: '내용삭제',
	DLPClientLogExecute: '프로그램 실행',
	DLPClientLogNetwork: '네트워크 연결',
	DLPClientLogFetchURL: '웹 접속',
	DLPClientLogUploadURL: '웹 업로드',
	DLPClientLogMount: '마운트',
	DLPClientLogUnmount: '마운트 해제',
	DLPClientLogSendWebMail: '웸 메일',
	DLP_CLIENT_LOG_VIER: '조회',
	DLP_CLIENT_LOG_VIEW_TITLE: '세부 내용 보기',
	DLP_CLIENT_LOG_LOGGED_AT: '일시',
	DLP_CLIENT_LOG_USERNAME: '사용자(클라이언트 번호)',
	DLP_CLIENT_LOG_TYPE: '로그 타입',
	DLP_CLIENT_LOG_PATH: '파일경로',
	DLP_CLIENT_LOG_SIGNING_ID: '프로그램 식별자',
	DLP_CLIENT_LOG_HASH: '프로그램 해시',
	DLP_CLIENT_LOG_SOURCE_PATH: '시작 경로',
	DLP_CLIENT_LOG_DEST_PATH: '목적지 경로',
	DLP_CLIENT_LOG_LOCAL_ENDPOINT: '출발지 주소',
	DLP_CLIENT_LOG_REMOTE_ENDPOINT: '목지지 주소',
	DLP_CLIENT_LOG_WEB_UPLOAD_FILE: '웹 업로드 파일',
	DLP_CLIENT_LOG_MAIL_RECIEVER: '받는사람',
	DLP_CLIENT_LOG_MAIL_CC: '참조',
	DLP_CLIENT_LOG_MAIL_TITLE: '제목',
	DLP_CLIENT_LOG_MAIL_CONTENTS: '내용',
	DLP_CLIENT_LOG_PRINT_DOC_FORMAT: '작업 포맷',
	DLP_CLIENT_LOG_PRINT_COPIES: '복사 수',
	DLP_CLIENT_LOG_PRINT_JOB_NAME: '작업 이름',
	DLP_CLIENT_LOG_PRINT_JOB_CREATED_AT: '생성일시',
	DLP_CLIENT_LOG_PRINT_JOB_PROCESSING_AT: '수행일시',
	DLP_CLIENT_LOG_PRINT_JOB_COMPLETED_AT: '완료일시',
	DLP_CLIENT_LOG_PRINT_PRINTER_URL: '프린트 URL',
	DLP_CLIENT_LOG_PRINT_JOB_STATE_MESSAGE: '프린트 상태메세지',
	DLP_CLIENT_LOG_PRINT_JOB_STATE_REASONS: '프린트 상태 사유',
	DLP_CLIENT_LOG_CONTROL_INFO: '프린트 작업 세부 정보',
	DLP_CLIENT_LOG_PRINT_FILE_DOWNLOAD_TITLE: '프린트 다운로드',
	DLP_CLIENT_LOG_PRINT_FILE_DOWNLOAD_CONTENT: '프린트된 파일을 다운로드하시겠습니까?',
	DLP_EXECUTE_PROGRAM: '실행제어',
	DLP_PROGRAM_HASH: '프로그램 해시',
	DLP_PROGRAM_SIGNING_ID: '프로그램 식별자',
	DLP_PROGRAM_MANAGE_NAME: '프로그램 관리명',
	DLP_PROGRAM_NAME: '프로그램명',
	DLP_PROCESS_TYPE: '프로세스 타입',
	DLP_EXECUTE_CONTROL_TITLE: '실제제어 설정',
	DLP_EXECUTE_CONTROL_CONTENT: '그룹의 실행제어를 설정하시겠습니까?',
	DLP_EXECUTE_CONTROL_DELETE_CONTENT: '그룹의 실행제어를 삭제하시겠습니까?',
	DLP_NETWORK: '네트워크제어',
	DLP_BLUETOOTH_PAIRING: '블루투스 페어링',
	DLP_BLUETOOTH_FILE: '블루투스 파일 전송',
	DLP_MESSENGER: '메신저 파일 전송',
	DLP_AIRDROP: '에어드랍',
	DLP_PROGRAM_INSTALL: '프로그램 설치',
	DLP_MOUNT: '마운트허용',
	DLP_TAKEOUT_APPROVING: '반출방식',
	ASCENDING: '오름차순',
	DESCENDING: '내림차순',
	SORT_DATE: '날짜 정렬',
	TEMP_RELEASE: '임시해제',
	TYPE: '종류',
	INSTALL: '설치',
	INSTALL_LIST: '설치 목록',
	HAPPEND_AT: '발생일시',
	ALERT_TYPE: '경고 종류',
	ALERT_INFO: '경고 내용',
	DLP_CLIENT_BLOCKED_LOG: '차단 로그',
	DLP_CLIENT_MONITORING_LOG: '모니터링 로그',
	DLP_CLIENT_RELEASE_BLOCK: '해제 로그',
	NO_LOGIN: '미로그인',
	VERSION: '버전',
	LOGGED_IN_AT: '로그인 일시',
	LOGGED_OFF_AT: '로그오프 일시',
	CONNECTED_STATUS: '연결 상태',
	CHECKED_AT: '확인 시각',
	ACTIVE: '활성화',
	INACTIVE: '비활성',
	NOT_INSTALLED: '미설치',
	BLUETOOTH_LIST: '블루투스 목록',
	DLP_CLIENT_DELETE_TITLE: '클라이언트 제거',
	DLP_CLIENT_DELETE_CONTENT: '클라이언트를 제거 하시겠습니까?',
	DLP_CLIENT_DELETE_SUCCESS: '클라이언트를 제거하였습니다',
	DLP_CLIENT_DELETE_FAIL: '클라이언트를 제거에 실패하였습니다.',
	WEBLINK_COMMENT_1: '웹 링크 서비스 포트와 쿠독 웹 포트가 다른경우 관리자 재로그인이 필요합니다',
	WEBLINK_RESTRICT_EXTS: '웹 링크 제한 확장자',
	WEBLINK_RESTRICT_FILE_SIZE: '웹 링크 제한 파일 크기',
	WEBLINK_RESTRICT_TOTAL_SIZE: '웹 링크 제한 전체 크기',
	DLP_POLICY_WHITE_URL_MANAGEMENT: '업로드 URL 관리',
	DLP_POLICY_WHITE_URL_NAME: 'URL 관리명',
	DLP_POLICY_WHITE_URL_REGEX: 'URL 정규식',
	DLP_POLICY_WHITE_URL_TITLE: '업로드 URL 설정',
	DLP_POLICY_WHITE_URL_CONTENT: '그룹의 업로드 URL을 추가 하시겠습니까?',
	DLP_POLICY_WHITE_URL_DELETE_CONTENT: '그룹의 업로드 URL을 삭제하시겠습니까?',
	DLP_POLICY_WHITE_URL_REGEX_PLACEHOLDER: 'EX) *.coodoc.com',
	DLP_POLICY_WHITE_URL_MESSAGE_1: 'URL 관리명을 입해해 주세요',
	USER_LOCK: '계정 잠금',
	OSR_ORG_TITLE: '인사연동 조직 설정',
	OSR_ORG_CODE: '조직코드',
	ALLOW_TAKEOUT_PRIVATE_FOLDER: '개인폴더 반출',
	ALLOW_TAKEOUT_FOLDER: '조직폴더 반출',
	UNAVAILABLE: '불허',
	FOLLOWS_FOLDER_MASTER_POLICIES: '조직폴더정책 사용',
	BLACK_LIST: '블랙 리스트',
	WHITE_LIST: '화이트 리스트',
	APPROVED: '승인 되었습니다',
	REJECTED: '거절 되었습니다',
	FAILED: '수정에 실패하였습니다',
	WEBLINK_URL: '웹 링크 URL',
	WEBLINK_COPY_MESSAGE_2: '웹 링크 반출을 신청하였습니다. 승인 후 다운로드 가능합니다',
	CONTEXT_MESSAGE_7: '업로드 할수없는 위치입니다',
	CONTEXT_MESSAGE_8: '문서중앙화 사용중 다운로드를 할 수 없습니다',
	CONTEXT_MESSAGE_9: '다운로드를 할 수 없습니다',
	CONTEXT_MESSAGE_10: "'%s' 중복되는 파일이 있어 이동 할 수없습니다",
	FILE_DROP_TITLE: '파일을 드롭하세요',
	CHANGE_VIEW_LIST: '목록 보기로 전환',
	CHANGE_VIEW_GRID_1: '바둑판 보기로 전환',
	CONTEXT_CONFIRM_TITLE_1: '파일 삭제',
	CONTEXT_CONFIRM_CONTENT_1: '파일을 삭제 하시겠습니까?',
	CONTEXT_MESSAGE_11: '휴지통은 이동 할 수 없습니다',
	CONTEXT_MESSAGE_12: '특수 폴더의 파일 또는 폴더의 변경은 불가능합니다',
	CONTEXT_MESSAGE_13: '사용 가능한 메뉴가 없습니다',
	CONTEXT_MESSAGE_14: '다운로드 할 파일을 선택해 주세요',
	SHARE: '공유하기',
	DEFAULT_POLICY_SETTING: '기본 정책 설정',
	DEFAULT_POLICY: '기본 정책',
	COPY_POLICY: '정책 복사',
	POLICY_GROUP_TITLE: '정책 그룹 추가',
	GROUP_NAME: '그룹명',
	EXPLANATION: '설명',
	DLP_ALLOW_RELEASE_STORAGE: '매체 제어',
	DLP_ALLOW_RELEASE_NETWORK: '네트워크 제어',
	DLP_ALLOW_RELEASE_EXECUTE: '실행 제어',
	DLP_ALLOW_RELEASE_UPLOAD_URL: '브라우저 업로드 제어',
	DLP_ALLOW_RELEASE_BLUETOOTH: '블루투스 제어',
	DLP_ALLOW_RELEASE_MESSENGER: '메신저 제어',
	DLP_ALLOW_RELEASE_AIRDROP: '에어드랍 제어',
	DLP_POLICY_GROUP_SETTING: '보안 설정',
	DLP_POLICY_GROUP_NETWORK_TITLE: '네트워크제어 설정',
	DLP_POLICY_GROUP_NETWORK_CONTENT_1: '그룹의 네트워크제어를 수정하시겠습니까?',
	DLP_POLICY_GROUP_NETWORK_CONTENT_2: '그룹의 네트워크제어를 삭제하시겠습니까?',
	DLP_POLICY_GROUP_NETWORK_CONTENT_3: '그룹의 네트워크제어를 설정하시겠습니까?',
	DLP_POLICY_GROUP_GRANT: '차단 매체에 권한 부여',
	DLP_POLICY_GROUP_GRANT_TITLE: '권한제어 설정',
	DLP_POLICY_GROUP_GRANT_CONTENT_1: '그룹의 권한제어를 수정하시겠습니까?',
	DLP_POLICY_GROUP_GRANT_CONTENT_2: '그룹의 권한제어를 삭제하시겠습니까?',
	DLP_POLICY_GROUP_GRANT_CONTENT_3: '그룹의 권한제어를 설정하시겠습니까?',
	DLP_POLICY_GROUP_MESSAGE_1: '정책 그룹을 등록하였습니다',
	DLP_POLICY_GROUP_MESSAGE_2: '정책 그룹을 복사하였습니다',
	DLP_POLICY_GROUP_MESSAGE_3: '정책 그룹을 선택해 주세요',
	DLP_POLICY_GROUP_DEFAULT_TITLE: '기본 정책 설정',
	DLP_POLICY_GROUP_RELEASE: '해제 요청',
	DLP_POLICY_GROUP_DEFAULT_CONTENT_1: '그룹의 기본정책을 설정하시겠습니까?',
	DLP_POLICY_GROUP_DEFAULT_CONTENT_2: '그룹의 기본정책을 전체 정책으로 초기화하시겠습니까?',
	DLP_POLICY_GROUP_RELEASE_TITLE: '해제 요청 허용 여부 설정',
	DLP_POLICY_GROUP_RELEASE_CONTENT_1: '그룹의 해제 요청 허용 여부를 설정하시겠습니까?',
	DLP_POLICY_RELEASE_DURATION: '해제 허용 시간',
	DLP_POLICY_RELEASE_DURATION_TITLE: '해제 허용 시간 설정',
	DLP_POLICY_RELEASE_DURATION_CONTENT_1: '그룹의 해제 허용 시간을 설정하시겠습니까?',
	DLP_NETWORK_MANAGE_NAME: '네트워크 관리명',
	DLP_NETWORK_INBOUND_PORT: '인바운스 포트',
	DLP_NETWORK_OUTBOUND_DESTINATION: '아웃바운스 주소',
	ALL_PROGRAM: '모든 프로그램',
	NOT_SELECTED: '미지정',
	STORAGE_NAME: '매체명',
	ALLOW_READ: '읽기 허용',
	ALLOW_WRITE: '쓰기 허용',
	ALLOW_EXECUTE: '실행 허용',
	IOS_DOWNLOAD_CONTENT: '다운로드를 하시겠습니까?',
	VIEW: '보기',
	MB_SETTING_SERVER_INFO: '접속정보 변경',
	MB_SETTING_BIOMETRICS_USE: '생체인증 사용',
	MB_SETTING_BIOMETRICS_UNUSED: '생체인증 미사용',
	MB_SETTING_BIOMETRICS_COMPLETE: '생체인증 등록 완료',
	FOLDER_ERROR_MESSAGE_6: '이미 입력창이 있습니다',
	EXPAND_ALL: '모두 열기',
	COLLAPSE_ALL: '모두 접기',
	EXPIRE_AT_CHANGE: '만료일 변경',
	EXPIRE_AT_CHANGE_MESSAGE_1: '만료일을 변경하시겠습니까?',
	EXPIRE_AT_CHANGE_MESSAGE_2: '만료일을 변경하였습니다',
	EXPIRE_AT_RELEASE: '만료일 해제',
	EXPIRE_AT_RELEASE_MESSAGE_1: '만료일을 해제하시겠제습니까?',
	EXPIRE_AT_RELEASE_MESSAGE_2: '만료일을 해제하였습니다',
	EXPIRE_AT_ERROR_MESSAGE_1: '만료일을 선택해 주세요',
	DLP_CLIENT_MESSAGE_1: '전체 사용자의 설치를 허용하시겠습니까?',
	DLP_CLIENT_MESSAGE_2: '전체 사용자의 설치를 잠그시겠습니까?',
	DLP_CLIENT_MESSAGE_3: '사용자의 설치를 허용하시겠습니까?',
	DLP_CLIENT_MESSAGE_4: '사용자의 설치를 잠그시겠습니까?',
	DLP_CLIENT_MESSAGE_5: '설정에 실패하였습니다',
	DLP_CLIENT_MESSAGE_6: '설정이 반영되었습니다',
	DLP_CLIENT_MESSAGE_7: 'DLP 클라이언트를 강제 업데이트 하시겠습니까?',
	DLP_CLIENT_TITLE_1: '설치 허용 여부',
	DLP_CLIENT_TITLE_2: 'DLP 강제 업데이트',
	ALLOW_INSTALL: '설치 허용',
	LOCK_INSTALL: '설치 잠금',
	ALL_ALLOW_INSTALL: '전체 설치 허용',
	ALL_LOCK_INSTALL: '전체 설치 잠금',
	PATH: '경로',
	PATH_REG: '경로(정규식)',
	PATH_STORAGE: '가상매체',
	PATH_STORAGE_TITLE_1: '가상매체 삭제',
	PATH_STORAGE_TITLE_2: '가상매체 수정',
	PATH_STORAGE_TITLE_3: '가상매체 추가',
	PATH_STORAGE_CONTENT_1: '가상매체를 삭제 하시겠습니까?',
	PATH_STORAGE_MESSAGE_1: '가상매체가 삭제 되었습니다',
	PATH_STORAGE_MESSAGE_2: '가상매체가 삭제에 실패하였습니다',
	PATH_STORAGE_MESSAGE_3: '가상매체를 추가하였습니다',
	PATH_STORAGE_MESSAGE_4: '가상매체 추가에 실패하였습니다',
	PATH_STORAGE_MESSAGE_5: '가상매체를 수정하였습니다',
	PATH_STORAGE_MESSAGE_6: '가상매체 수정에 실패하였습니다',
	DLP_PROCESS_TYPE_UNKNOWN: '알 수 없는 프로그램',
	DLP_PROCESS_TYPE_NORMAL: '일반 프로그램',
	DLP_PROCESS_TYPE_SYSTEM: '시스템 프로그램',
	DLP_PROCESS_TYPE_CHROME: '크롬 브라우저',
	PROGRAM: '프로그램',
	PROGRAM_TITLE_1: '프램그램 삭제',
	PROGRAM_TITLE_2: '프램그램 수정',
	PROGRAM_TITLE_3: '프램그램 추가',
	PROGRAM_CONTENT_1: '프로그램을 삭제 하시겠습니까?',
	PROGRAM_MESSAGE_1: '프로그램이 삭제 되었습니다',
	PROGRAM_MESSAGE_2: '프로그램 삭제에 실패하였습니다',
	PROGRAM_MESSAGE_3: '프로그램을 추가하였습니다',
	PROGRAM_MESSAGE_4: '프로그램 추가에 실패하였습니다',
	PROGRAM_MESSAGE_5: '프로그램을 수정하였습니다',
	PROGRAM_MESSAGE_6: '프로그램 수정에 실패하였습니다',
	NETWORK: '네트워크',
	NETWORK_TITLE_1: '네트워크 삭제',
	NETWORK_TITLE_2: '네트워크 수정',
	NETWORK_TITLE_3: '네트워크 추가',
	NETWORK_CONTENT_1: '네워워크를 삭제 하시겠습니까?',
	NETWORK_MESSAGE_1: '네워워크가 삭제 되었습니다',
	NETWORK_MESSAGE_2: '네트워크 삭제에 실패하였습니다',
	NETWORK_MESSAGE_3: '네트워크를 추가하였습니다',
	NETWORK_MESSAGE_4: '네워워크 추가에 실패하였습니다',
	NETWORK_MESSAGE_5: '네트워크를 수정하였습니다',
	NETWORK_MESSAGE_6: '네트워크 수정에 실패하였습니다',
	DLP_POLICY_TITLE: '기본 설정',
	DLP_POLICY_STORAGE_TYPE: 'Storage 종류',
	DLP_HEALTH_MODULE_SECURE: 'dlp 클라이언트',
	DLP_HEALTH_MODULE_EPS: '제어 모듈',
	DLP_HEALTH_MODULE_NETWORK: '네트워크 모듈',
	DLP_HEALTH_MODULE_CHROME: '크롬 확장프로그램',
	MAC_ADDRESS: 'mac',
	ENTER_REASON: '사유를 입력해 주세요',
	SELECT_REQUEST: '요청을 선택해 주세요',
	OFFLINE_DRIVE: '오프라인 드라이브',
	OFFLINE_DRIVE_CONFIRM_1: '오프라인 드라이브를 거절하시겠습니까?',
	OFFLINE_DRIVE_CONFIRM_2: '오프라인 드라이브를 승인하시겠습니까?'
};
export default locale;
