import FusePageSimple from '@fuse/core/FusePageSimple';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import commonUtils from 'app/utils/CommonUtils';

import {
	offlineDriveTakeoutFiles,
	offlineDriveTakeoutFilesPage,
	changeOfflineTakeoutsRowsPerPage
} from 'app/store/docbase/admin/systemSlice';
import * as commonTypes from 'app/main/constants/CommonTypes';

import ContentHeader from '../component/ContentHeader';
import LogsTable from './LogsTable';

function Logs() {
	const dispatch = useDispatch();
	const [loading, setLoading] = React.useState(true);
	const pageLayout = React.useRef(null);
	const { t } = useTranslation('docbase');

	const today = new Date();
	const [searchObj, setSearchObj] = React.useState({
		startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
		endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
		operator: ''
	});

	useEffect(() => {
		doSearch();
	}, [searchObj]);

	function doSearch() {
		//	마지막날도 검색하기위하여 +1 해준다
		let days = commonUtils.getDateDifference(searchObj.startDate, searchObj.endDate);
		if (days <= 0) {
			days = 0;
		} else {
			days++;
		}
		setLoading(true);
		dispatch(
			offlineDriveTakeoutFiles({
				page: 1,
				day: 0,
				userID: searchObj.operator
			})
		).then(() => {
			setLoading(false);
		});
	}

	function handleChangePage(event, value) {
		setLoading(true);
		dispatch(offlineDriveTakeoutFilesPage({ page: value + 1 })).then(() => setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		setLoading(true);
		dispatch(changeOfflineTakeoutsRowsPerPage({ value: event.target.value }));
		dispatch(
			offlineDriveTakeoutFiles({
				page: 1,
				day: 0,
				userID: searchObj.operator
			})
		).then(() => setLoading(false));
	}

	return (
		<FusePageSimple
			classes={{
				content: 'flex'
			}}
			header={<ContentHeader />}
			content={
				<LogsTable
					loading={loading}
					searchObj={searchObj}
					setLoading={setLoading}
					fnSetSearchObj={value => setSearchObj({ ...searchObj, operator: value })}
					fnDoSearch={() => doSearch()}
					fnHandleChangePage={(e, v) => handleChangePage(e, v)}
					fnHandleChangeRowsPerPage={e => handleChangeRowsPerPage(e)}
				/>
			}
			innerScroll
			sidebarInner
			ref={pageLayout}
		/>
	);
}

export default Logs;
