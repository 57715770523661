/* eslint-disable no-restricted-globals */
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	TablePagination,
	Paper,
	TextField,
	MenuItem,
	Tooltip,
	IconButton
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import CustomIcon from 'app/main/icon/CustomIcon';
import { onClickDoc, ransomHistory, getRansomHistory, changePiiRowsPerPage } from 'app/store/docbase/admin/settingSlice';
import commonUtils from 'app/utils/CommonUtils';
import RansomTableHead from './RansomTableHead';

const useStyles = makeStyles(theme => ({
	datePicker: {
		'& .MuiOutlinedInput-notchedOutline': { display: 'none' },
		'& input': {
			border: '1px solid rgba(0, 0, 0, 0.23)',
			borderRadius: 6,
			width: 130,
			textAlign: 'center',
			padding: '9.5px 0'
		},
		'& .MuiOutlinedInput-root.Mui-focused input': {
			boxShadow: '0 0 0 2px #000 inset'
		},
		'& .MuiButtonBase-root': {
			background: '#eceff4',
			borderRadius: 6,
			border: '1px solid #d9dee5',
			padding: 7
		},
		'& .MuiOutlinedInput-adornedEnd': {
			paddingRight: 0
		}
	},
	contentWrapper: {
		height: `${window.innerHeight - 296}px`,
		padding: '0 20px'
	}
}));

function RansomTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const { t } = useTranslation('docbase');

	const { rowsPerPage, currentPage, totalRows } = useSelector(({ docbase }) => docbase.admin.setting.ransomObj);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 296}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {}, [dispatch]);

	function handleRowClick(docObj) {
		dispatch(onClickDoc({ docObj }));
	}

	if (props.loading) {
		return <FuseLoading />;
	}

	function handleChangePage(event, value) {
		props.setLoading(true);
		dispatch(getRansomHistory({ page: value + 1 })).then(() => props.setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		props.setLoading(true);
		dispatch(changePiiRowsPerPage({ value: event.target.value }));
		dispatch(
			ransomHistory({
				page: 1,
				status: props.searchObj.status,
				day: commonUtils.getDateDifference(props.searchObj.startDate, props.searchObj.endDate),
				user: props.searchObj.user,
				path: props.searchObj.path,
				clientAddress: props.searchObj.clientAddress,
				infectedFilePath: props.searchObj.infectedFilePath
			})
		).then(() => props.setLoading(false));
	}

	let curID = 0;
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper>
				<div className="table-search-wrapper">
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="">
							<span className="title">{t('ACCESS_TYPE')}</span>
							<TextField
								select
								className="select"
								value={props.searchObj.status}
								fullWidth
								variant="outlined"
								size="small"
								onChange={e => {
									props.fnSetSearchObj('status', e.target.value);
								}}
							>
								<MenuItem value={0}>{t('RANSOMWARE_STATUS_NONE')}</MenuItem>
								<MenuItem value={1}>{t('RANSOMWARE_STATUS_COM')}</MenuItem>
								<MenuItem value={2}>{t('RANSOMWARE_STATUS_BLOCK')}</MenuItem>
							</TextField>
						</div>
						<div className="date-picker-wrapper">
							<span className="title">{t('RESTORE_DAY')}</span>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="start_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.startDate}
										onChange={d => {
											if (!isNaN(d.getTime())) {
												if (props.searchObj.startDate.getTime() !== d.getTime())
													props.fnSetSearchObj('startDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.startDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('startDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date start'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
							<div className="tilde">~</div>
							<div className="">
								<MuiPickersUtilsProvider utils={DateFnsUtils} className="flex-1">
									<KeyboardDatePicker
										className={classes.datePicker}
										fullWidth
										disableToolbar
										invalidDateMessage=""
										autoOk
										size="small"
										variant="inline"
										inputVariant="outlined"
										format="yyyy/MM/dd"
										id="end_date"
										maxDate={new Date('2999-12-31')}
										value={props.searchObj.endDate}
										onChange={d => {
											if (!isNaN(d.getTime())) {
												if (props.searchObj.endDate.getTime() !== d.getTime())
													props.fnSetSearchObj('endDate', d);
											}
										}}
										// onBlur={e => {
										// 	const val = commonUtils.getStringToDate(e.target.value.replaceAll('/', '-'));
										// 	if (!isNaN(val.getTime()) && props.searchObj.endDate.getTime() !== val.getTime())
										// 		props.fnSetSearchObj('endDate', val);
										// }}
										KeyboardButtonProps={{
											'aria-label': 'change date end'
										}}
									/>
								</MuiPickersUtilsProvider>
							</div>
						</div>
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton
									key="refresh"
									aria-label="refresh"
									color="secondary"
									onClick={() => props.fnDoSearch()}
								>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<RansomTableHead
							searchObj={props.searchObj}
							fnSearch={(ransomwarePath, userId, clientAddr, infeFiles) => {
								if (props.searchObj.path !== ransomwarePath) {
									props.fnSetSearchObj('path', ransomwarePath);
								}
								if (props.searchObj.user !== userId) {
									props.fnSetSearchObj('user', userId);
								}
								if (props.searchObj.clientAddress !== clientAddr) {
									props.fnSetSearchObj('clientAddress', clientAddr);
								}
								if (props.searchObj.infectedFilePath !== infeFiles) {
									props.fnSetSearchObj('infectedFilePath', infeFiles);
								}
							}}
						/>

						<TableBody>
							{props.data.map(d => {
								const isRowSpan = curID === d.id;
								curID = d.id;
								let strStatus = t('RANSOMWARE_FILE_STATUS_COM');
								if (d.file_status === 2) {
									strStatus = t('RANSOMWARE_FILE_STATUS_RESTORE');
								} else if (d.file_status === 3) {
									strStatus = t('RANSOMWARE_FILE_STATUS_INFECTION');
								}
								if (!isRowSpan) {
									return (
										<TableRow
											className="cursor-default"
											role="checkbox"
											tabIndex={-1}
											key={d.id}
											onClick={event => handleRowClick(d)}
										>
											<TableCell className="left" align="left" rowSpan={d.infected_files.length}>
												{d.user_id}
											</TableCell>
											<TableCell className="left" align="left" rowSpan={d.infected_files.length}>
												{d.client_address.split(':')[0]}
											</TableCell>
											<TableCell className="left" align="left" rowSpan={d.infected_files.length}>
												<div>
													<p className="log-name">
														{commonUtils.splitLastDelimiterString(d.ransomware_path)}
														<br />
														<span>
															[
															{d.status === 2
																? t('RANSOMWARE_STATUS_BLOCK')
																: t('RANSOMWARE_STATUS_COM')}
															]
														</span>
													</p>
													<p className="log-path">{commonUtils.splitPreDelimiter(d.ransomware_path)}</p>
												</div>
												<span>{d.ransomware_path}</span>
											</TableCell>
											<TableCell className="left" align="left">
												<span>{d.file_path}</span>
												<span className="ml-8">[{strStatus}]</span>
											</TableCell>
											<TableCell className="left" align="left" rowSpan={d.infected_files.length}>
												{commonUtils.getDateStringToLocalDate(d.detected_at)}
											</TableCell>
										</TableRow>
									);
								}
								return (
									<TableRow
										className="cursor-default"
										role="checkbox"
										tabIndex={-1}
										key={d.id}
										onClick={event => handleRowClick(d)}
									>
										<TableCell className="left" align="left">
											<span>{d.file_path}</span>
											<span className="ml-8">[{strStatus}]</span>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<TablePagination
					className="flex-shrink-0"
					component="div"
					count={totalRows}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[100, 250, 500]}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(e, val) => {
						handleChangePage(e, val);
						contentTable.current.scrollTop = 0;
					}}
					onChangeRowsPerPage={e => {
						handleChangeRowsPerPage(e);
						contentTable.current.scrollTop = 0;
					}}
				/>
			</Paper>
		</div>
	);
}

export default RansomTable;
