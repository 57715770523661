/* eslint-disable no-await-in-loop */
import React from 'react';
import Formsy from 'formsy-react';
import { TextFieldFormsy } from '@fuse/core/formsy';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, DialogTitle, Backdrop, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import {
	registerUserGroup,
	getDefaultUserPolicy,
	setDefaultUserPolicy,
	getExecuteControls,
	setExecuteControl,
	getNetworkControls,
	registerNetworkControl,
	getGrantAccesses,
	grantAccess,
	getWhiteURLs,
	registerWhiteURL
} from 'app/store/docbase/admin/dlpSlice';

const useStyles = makeStyles(theme => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff'
	},
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%',
			maxWidth: 604
		}
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			background: '#ffffff',
			borderRadius: 6,
			padding: 20
		},
		'&>.content>.row': {
			display: 'flex',
			alignItems: 'center',
			margin: '15px 0'
		},
		'&>.content>.row:first-child': {
			marginTop: 0
		},
		'&>.content>.row:last-child': {
			marginBottom: 0
		},
		'&>.content>.row>.title': {
			width: 160,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.title>span:before': {
			color: '#00cfe2',
			content: '"*"',
			marginRight: '5px'
		},
		'&>.content>.row>.input-area': {
			width: '100%',
			maxWidth: 382,
			cursor: 'default',
			fontSize: 14
		},
		'&>.content>.row>.input-area>.hint': {
			color: '#9f9f9f',
			fontSize: '1.2rem'
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 20,
		'& .MuiButton-root': {},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function CreateGroupDialog(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('docbase');
	const [formValid, setFormValid] = React.useState({});
	const [openBackdrop, setOpenBackdrop] = React.useState(false);
	const formRef = React.useRef(null);

	function handleSubmit(values) {
		const validationErrors = {};
		let isValid = true;
		if (values.name === '') {
			isValid = false;
		}
		if (values.desc === '') {
			isValid = false;
		}
		setFormValid(validationErrors);

		if (isValid && !openBackdrop) {
			setOpenBackdrop(true);
			(async () => {
				const respReg = await registerUserGroup(values.name, values.desc);

				if (respReg.result) {
					if (props.type === 1) {
						await copyGroupPolicy(respReg.group_id);
						dispatch(showMessage({ message: t('DLP_POLICY_GROUP_MESSAGE_2') }));
						props.fnHandleOK();
						props.fnHandleClose();
					} else {
						dispatch(showMessage({ message: t('DLP_POLICY_GROUP_MESSAGE_1') }));
						props.fnHandleOK();
						props.fnHandleClose();
					}
				} else {
					dispatch(showErrorMessage({ message: t('REGISTER_ERROR_MESSAGE_1') }));
					// if (err.error === commonTypes.SERVER_ERROR_EXISTS) {
					// }
				}

				setOpenBackdrop(false);
			})();
		}
	}

	async function copyGroupPolicy(groupID) {
		const defaultPolicy = await getDefaultUserPolicy(props.selectedID);
		const whiteURLPolicy = [];
		const grantAccessPolicy = [];
		const executePolicy = [];
		const networkPolicy = [];

		const respWhiteURLs = await getWhiteURLs();
		respWhiteURLs.forEach(c => {
			if (c.group_id === props.selectedID) {
				whiteURLPolicy.push(c);
			}
		});
		const respGrantAccess = await getGrantAccesses();
		respGrantAccess.forEach(c => {
			if (c.group_id === props.selectedID) {
				grantAccessPolicy.push(c);
			}
		});

		const respExecuteControl = await getExecuteControls();
		respExecuteControl.forEach(c => {
			if (c.group_id === props.selectedID) {
				executePolicy.push(c);
			}
		});
		const respNetworkControl = await getNetworkControls();

		respNetworkControl.forEach(c => {
			if (c.group_id === props.selectedID) {
				networkPolicy.push(c);
			}
		});

		await setDefaultUserPolicy(
			groupID,
			defaultPolicy.execute_control,
			defaultPolicy.network_control,
			defaultPolicy.takeout_approving,
			defaultPolicy.mount,
			defaultPolicy.bluetooth,
			defaultPolicy.bluetooth_pairing,
			defaultPolicy.messenger,
			defaultPolicy.install,
			defaultPolicy.allow_release_storage,
			defaultPolicy.allow_release_network,
			defaultPolicy.allow_release_execute,
			defaultPolicy.allow_release_upload_url,
			defaultPolicy.allow_release_bluetooth,
			defaultPolicy.allow_release_messenger,
			defaultPolicy.max_release_duration_months,
			defaultPolicy.max_release_duration_days,
			defaultPolicy.max_release_duration_hours,
			defaultPolicy.max_release_duration_minutes
		);

		for (let i = 0; i < executePolicy.length; i++) {
			await setExecuteControl(groupID, executePolicy[i].program);
		}

		for (let i = 0; i < networkPolicy.length; i++) {
			await registerNetworkControl(
				groupID,
				networkPolicy[i].program,
				networkPolicy[i].network_name,
				networkPolicy[i].network_name === '' ? networkPolicy[i].inbound_ports : '',
				networkPolicy[i].network_name === '' ? networkPolicy[i].outbound_destinations : ''
			);
		}

		for (let i = 0; i < grantAccessPolicy.length; i++) {
			await grantAccess(
				groupID,
				grantAccessPolicy[i].program,
				grantAccessPolicy[i].storage,
				grantAccessPolicy[i].readable,
				grantAccessPolicy[i].writable,
				grantAccessPolicy[i].executable
			);
		}

		for (let i = 0; i < whiteURLPolicy.length; i++) {
			await registerWhiteURL(
				whiteURLPolicy[i].name,
				groupID,
				whiteURLPolicy[i].url,
				whiteURLPolicy[i].url_regex.replaceAll('*', '.+')
			);
		}

		return true;
	}

	return (
		<div>
			<Dialog className={classes.root} open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title">
				<Backdrop className={classes.backdrop} open={openBackdrop}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<DialogTitle className={classes.title} id="form-dialog-title">
					{t('POLICY_GROUP_TITLE')}
				</DialogTitle>
				<Formsy
					name="registerForm"
					className="flex flex-col justify-center w-full"
					onSubmit={values => handleSubmit(values)}
					validationErrors={formValid}
					ref={formRef}
				>
					<DialogContent className={classes.body}>
						<div className="content">
							<div className="row">
								<div className="title">
									<span>{t('GROUP_NAME')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('GROUP_NAME')}
										type="text"
										name="name"
										variant="outlined"
										autoFocus
										required
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className="row">
								<div className="title">
									<span>{t('EXPLANATION')}</span>
								</div>
								<div className="input-area">
									<TextFieldFormsy
										className=""
										placeholder={t('EXPLANATION')}
										type="text"
										name="desc"
										variant="outlined"
										required
										fullWidth
										size="small"
									/>
								</div>
							</div>

							<div className={classes.buttonWrapper}>
								<Button
									onClick={props.fnHandleClose}
									className="default"
									variant="contained"
									aria-label="cancel"
									type="button"
								>
									{t('CANCEL')}
								</Button>
								<Button variant="contained" color="secondary" className="" aria-label="Register" type="submit">
									{t(props.type === 0 ? 'ADD' : 'COPY')}
								</Button>
							</div>
						</div>
					</DialogContent>
				</Formsy>
			</Dialog>
		</div>
	);
}
