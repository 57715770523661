/* eslint-disable camelcase */
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseLoading from '@fuse/core/FuseLoading';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableRow, TablePagination, Paper, Tooltip, IconButton } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import commonUtils from 'app/utils/CommonUtils';
import CustomIcon from 'app/main/icon/CustomIcon';
import { onClickDoc, searchDupDocs, getDupDocs, changeDupRowsPerPage, getDupDocSize } from 'app/store/docbase/admin/settingSlice';
import { getStorageDashboard } from 'app/store/docbase/admin/systemSlice';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DupDocsTableHead from './DupDocsTableHead';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip, Legend, ChartDataLabels);

const useStyles = makeStyles(theme => ({
	contentWrapper: {
		height: `${window.innerHeight - 366}px`,
		padding: '0 20px'
	}
}));

function DupDocsTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { t } = useTranslation('docbase');
	const { docs, rowsPerPage, currentPage, totalRows, total_pages, totalSize, oriSize } = useSelector(
		({ docbase }) => docbase.admin.setting.dupDocObj
	);
	const dashboardObj = useSelector(({ docbase }) => docbase.admin.system.dashboardObj);

	const contentTable = React.useRef();
	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
	const [fileHashObjs, setFileHashObjs] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const [searchObj, setSearchObj] = React.useState({
		path: ''
	});

	React.useEffect(() => {
		doSearch();
	}, [searchObj]);

	function doSearch() {
		setLoading(true);
		dispatch(
			searchDupDocs({
				page: 1,
				path: searchObj.path
			})
		).then(() => {
			setLoading(false);
		});
	}

	const [storageTotalSize, setStorageTotalSize] = React.useState({
		used: 0,
		free: 0
	});

	const [data, setData] = React.useState({
		labels: [''],
		datasets: [
			{ label: '절약 사용량', backgroundColor: 'lightpink', data: [0] },
			{
				label: '중복문서 사용량',
				backgroundColor: 'lightseagreen',
				data: [0],

				borderWidth: 1
			},
			{ label: '전체 사용량', backgroundColor: '#00cfe2', data: [0] }
		]
	});

	const options = {
		indexAxis: 'y',
		plugins: {
			tooltip: {
				enabled: true,
				backgroundColor: 'rgba(0,0,0,0.8)',
				titleFont: {
					size: 12
				},
				bodyFont: {
					size: 12
				},
				callbacks: {
					title: () => null,
					label: context => {
						if (context.raw === 0) {
							return '';
						}

						if (context.dataIndex === 0) {
							return `${context.raw.toFixed(2)}%`;
						}

						//	절약 사용량만 %로 표시
						if (context.datasetIndex === 0) {
							return commonUtils.changeByteToString(totalSize - oriSize);
						}

						return `${context.raw.toFixed(2)}%`;
					}
				}
			},
			datalabels: {
				align: 'center', // 라벨을 막대의 끝에 맞추기
				anchor: 'center', // 막대 끝에 라벨 위치
				clip: false, // 라벨이 차트 영역 밖으로 벗어날 수 있게 함
				color: 'black',
				font: { size: 11 },
				formatter: (value, context) => {
					if (value === '' || value === 0) return '';

					if (context.dataIndex === 0) {
						if (context.datasetIndex === 1) {
							return commonUtils.changeByteToString(oriSize);
						}
						if (context.datasetIndex === 2) {
							return commonUtils.changeByteToString(storageTotalSize.used - oriSize);
						}

						return commonUtils.changeByteToString(storageTotalSize.free);
					}

					//	절약 사용량만 %로 표시
					if (context.datasetIndex === 0) {
						return `${value.toFixed(2)}%`;
					}

					return commonUtils.changeByteToString(storageTotalSize.used);
				}
			}
		},
		scales: {
			x: {
				stacked: true,
				display: true,
				min: 0,
				max: 100,
				ticks: { beginAtZero: true }
			},
			y: {
				beginAtZero: true,
				stacked: true
			}
		},
		maintainAspectRatio: false
	};

	React.useEffect(() => {
		// console.log(loading);
		// if (searchObj.path === '' && !loading) {
		// 	dispatch(getStorageDashboard()).then(() => {});
		// 	getSizeObj();
		// }
	}, [total_pages]);

	useEffect(() => {
		if (dashboardObj.total.used)
			setStorageTotalSize({ used: dashboardObj.total.used.size, free: dashboardObj.total.free.size });
	}, [dashboardObj]);

	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 366}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		const hashObjs = {};
		docs.forEach(doc => {
			hashObjs[doc.file_hash] = {
				count: hashObjs[doc.file_hash] && hashObjs[doc.file_hash].count ? hashObjs[doc.file_hash].count + 1 : 1
			};
		});
		setFileHashObjs(hashObjs);
	}, [docs]);

	useEffect(() => {
		if (oriSize > 0) {
			const coodocTotalSize = storageTotalSize.used + storageTotalSize.free;
			const dupSize = (oriSize / coodocTotalSize) * 100;
			const oneSize = ((storageTotalSize.used - oriSize) / coodocTotalSize) * 100;
			const freeSize = (storageTotalSize.free / coodocTotalSize) * 100;

			const totalSize2 = storageTotalSize.used + (totalSize - oriSize);
			const saveSize = ((totalSize - oriSize) / totalSize2) * 100;
			const oneSize2 = (storageTotalSize.used / totalSize2) * 100;

			setData({
				labels: ['현재 사용량', '절약량'],
				datasets: [
					{ label: '절약 사용량', backgroundColor: 'lightpink', data: [0, saveSize] },
					{ label: '중복 사용량', backgroundColor: 'lightseagreen', data: [dupSize, 0] },
					{
						label: '현재 사용량',
						backgroundColor: '#00cfe2',
						data: [oneSize, oneSize2]
					},
					{ label: '남은 사용량', backgroundColor: '#8b95a1', data: [freeSize, 0] }
				]
			});
		}
	}, [totalSize, oriSize]);

	function handleRowClick(docObj) {
		dispatch(onClickDoc({ docObj }));
		// props.fnToggleRightSidebar();
	}

	if (loading) {
		return <FuseLoading />;
	}

	function handleChangePage(event, value) {
		setLoading(true);
		dispatch(getDupDocs({ page: value + 1 })).then(() => setLoading(false));
	}
	function handleChangeRowsPerPage(event) {
		setLoading(true);
		dispatch(changeDupRowsPerPage({ value: event.target.value }));
		dispatch(searchDupDocs({ page: 1, path: searchObj.path })).then(() => setLoading(false));
	}

	const StyledTableRow = withStyles(theme => ({
		root: { '&.bg': { backgroundColor: '#eaf4fd' } }
	}))(TableRow);

	let beforeHash = '';
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="content" style={{ width: '100%', height: '150px', padding: '0 20px' }}>
					<Bar data={data} options={options} />
				</div>

				<div className="table-search-wrapper" style={{ paddingTop: 0 }}>
					<div className="total-cnt-info">
						<span>
							{t('TOTAL')} <span className="count">{totalRows}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="search-form-wrapper">
						<div className="">
							<Tooltip title={t('REFRESH')} placement="bottom">
								<IconButton key="refresh" aria-label="refresh" color="secondary" onClick={() => doSearch()}>
									<CustomIcon className="small" type="refresh" fill="#333d4b" />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<DupDocsTableHead
							searchObj={searchObj}
							fnSearch={p => {
								if (searchObj.path !== p) {
									setSearchObj({ ...searchObj, path: p });
								}
							}}
						/>

						<TableBody>
							{docs.map((d, idx) => {
								const isRowSpan = d.file_hash === beforeHash;
								beforeHash = d.file_hash;

								return (
									<StyledTableRow
										className={!isRowSpan ? 'row-span' : ''}
										role="checkbox"
										tabIndex={-1}
										key={d.fid}
										onClick={event => handleRowClick(d)}
									>
										{isRowSpan ? (
											''
										) : (
											<>
												<TableCell
													className="left"
													component="td"
													scope="row"
													rowSpan={fileHashObjs[d.file_hash] ? fileHashObjs[d.file_hash].count : 0}
												>
													{commonUtils.splitLastDelimiterString(d.disp_path)}
												</TableCell>
												<TableCell
													className="left"
													component="td"
													scope="row"
													rowSpan={fileHashObjs[d.file_hash] ? fileHashObjs[d.file_hash].count : 0}
													style={{ fontSize: 11 }}
												>
													{commonUtils.changeByteToString(d.file_size)}
												</TableCell>
											</>
										)}
										<TableCell className="left" align="left" component="td" scope="row">
											<div>
												<p className="log-name">{commonUtils.splitLastDelimiterString(d.disp_path)}</p>
												<p className="log-path">{commonUtils.splitPreDelimiter(d.disp_path)}</p>
											</div>
										</TableCell>
									</StyledTableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>

				<TablePagination
					className="flex-shrink-0 border-t-1 mx-20"
					component="div"
					count={totalRows}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={[100, 250, 500]}
					page={currentPage - 1}
					labelRowsPerPage={t('ROW_PER_PAGE_LABEL')}
					labelDisplayedRows={({ from, to, count }) => `${count !== -1 ? count : to} 중 ${from}-${to}`}
					backIconButtonProps={{
						'aria-label': t('PAGE_PREV_BTN_LABEL')
					}}
					nextIconButtonProps={{
						'aria-label': t('PAGE_NEXT_BTN_LABEL')
					}}
					onChangePage={(e, value) => {
						handleChangePage(e, value);
						contentTable.current.scrollTop = 0;
					}}
					onChangeRowsPerPage={e => {
						handleChangeRowsPerPage(e);
						contentTable.current.scrollTop = 0;
					}}
				/>
			</Paper>
		</div>
	);
}

export default DupDocsTable;
