import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	TextField,
	Typography,
	IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});
const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const useStyles = makeStyles({
	root: {
		'& .MuiDialog-paper': { width: 380 }
	},
	title: {
		color: '#000000',
		fontSize: 22,
		padding: 30,
		textAlign: 'center'
	},
	content: {
		color: '#404040',
		fontSize: 14,
		padding: '0 30px',
		textAlign: 'center'
	},
	actions: {
		padding: 30,
		justifyContent: 'center',
		'& button:first-child': {
			marginRight: 16
		}
	}
});

export default function ConfirmRestore(props) {
	const { t } = useTranslation('docbase');
	const classes = useStyles();
	const [checkVal, setCheckVal] = React.useState('');
	const [invalidObj, setInvalidObj] = React.useState({ error: false, helperText: '' });
	function validationCheck() {
		if (!checkVal || checkVal.trim().length === 0) {
			setInvalidObj({ error: true, helperText: t('RESTORE_ERROR_MESSAGE_1') });
		} else if (checkVal === '복원시작') {
			props.fnHandleOK();
			setCheckVal('');
			setInvalidObj({ error: false, helperText: '' });
		} else {
			setInvalidObj({ error: true, helperText: t('RESTORE_ERROR_MESSAGE_2') });
		}
	}

	return (
		<Dialog open={props.open} onClose={props.fnHandleClose} aria-labelledby="form-dialog-title" className={classes.root}>
			<DialogTitle id="confirm-start-restore" className={classes.title}>
				{t('RESTORE')}
			</DialogTitle>
			<DialogContent className={classes.content}>
				<DialogContentText id="alert-dialog-slide-description">{t('RESTORE_MESSAGE_3')}</DialogContentText>
				<TextField
					className=""
					type="text"
					name="checkVal"
					fullWidth
					variant="outlined"
					value={checkVal}
					error={invalidObj.error}
					helperText={invalidObj.helperText}
					onChange={e => setCheckVal(e.target.value)}
				/>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button
					onClick={props.fnHandleClose}
					color=""
					variant="contained"
					className="default"
					aria-label="cancel"
					type="button"
				>
					{t('CANCEL')}
				</Button>
				<Button
					variant="contained"
					color="secondary"
					className=""
					type="button"
					aria-label="ok"
					onClick={e => validationCheck()}
				>
					{t('CONFIRM')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
