/* eslint-disable no-await-in-loop */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Tooltip, IconButton, Checkbox } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import CustomIcon from 'app/main/icon/CustomIcon';

const useStyles = makeStyles(theme => ({}));

export default function LogTypeCheckBox({ type, cons, icon, types, setTypes }) {
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const checkboxRef = React.useRef(null);

	return (
		<FormControlLabel
			control={
				<Checkbox
					inputRef={checkboxRef}
					checked={types[type]}
					onChange={e => {
						const tex = { ...types };
						tex[type] = !tex[type];
						setTypes(tex);
					}}
				/>
			}
			label={
				<Tooltip title={t(cons)} placement="bottom" arrow>
					<IconButton
						style={{ padding: 0 }}
						onClick={e => {
							e.stopPropagation();
							if (checkboxRef.current) {
								checkboxRef.current.click();
							}
						}}
					>
						<CustomIcon className="large" type={icon} fill="#333d4b" />
					</IconButton>
				</Tooltip>
			}
		/>
	);
}
