const locale = {
	DASHBOARD: 'Dashboard',
	MANAGEMENT_GROUP: 'Management',
	USER_MANAGEMENT: 'User',
	ORG_MANAGEMENT: 'Organization',
	SHARED_MANAGEMENT: 'Shared',
	SECUREDOCS_MANAGEMENT: 'Secure Document',
	SETTING_GROUP: 'Setting',
	RESTORE_SETTING: 'Restore',
	SERVER_SETTING: 'Miscellaneus',
	ACCESSLOG: 'Access logs',
	GLOBAL_SETTING: 'Collector',
	PRIVATE_SETTING: 'Collection for each user',
	PII_MANAGEMENT: 'Detecting PII(Personal Identifiable Information)',
	DUP_MANAGEMENT: 'Duplicated documents',
	WHITE_PROCESS_MANAGEMENT: 'White process',
	OSR_MANAGEMENT: 'Synchronizing with the HR Information System',
	FILE_ACCESS_LOG: 'File log',
	AUDIT_LOG: 'Audit log',
	LOGOUT: 'Log off',
	RANSOM_MANAGEMENT: 'Ransomware Detection',
	TAKEOUT_MANAGEMENT: 'Takeout Document',
	HOLD_FILES: 'Hold files',
	SETUP_INFO: 'Client',
	EXPORT_APPROVAL_MANAGEMENT: 'Managing the approver',
	INSTANT_LOG: 'Client log',
	CLIENT_RUNNING_LOG: 'Client execution log',
	DLP_CLIENT_INFO: 'DLP 클라이언트 정보',
	DLP_POLICY_SETTING: 'DLP 카테고리 설정',
	RELEASE_BLOCKING: 'DLP 임시 정책 관리',
	PRINT_LOG: 'DLP 프린트 로그',
	CLIENT_ACCESS_LOG: 'DLP 임시해제 로그',
	DLP_GROUP_SETTING: 'DLP 정책 설정',
	CLIENT_MONITORING_LOG: 'DLP 차단/유출 로그',
	CLIENT_ALERT_LOG: 'DLP 경고 로그',
	DLP_POLICY_GROUP: 'DLP 정책 관리',
	DLP_LOG_GROUP: 'DLP 로그',
	CLIENT_STORAGE_LOG: 'DLP 매체 로그',
	OFFLINE_DRIVE_MANAGEMENT: '오프라인드라이브 관리',
	OFFLINE_TAKEOUT_MANAGEMENT: '오프라인드라이브 반출 문서'
};

export default locale;
