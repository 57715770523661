import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import { TextField, Icon, IconButton, Box, Tooltip } from '@material-ui/core';
import { SimpleTreeView, TreeItem, TreeItem2Icon, TreeItem2Provider } from '@mui/x-tree-view';
import clsx from 'clsx';

import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
	TreeItem2Content,
	TreeItem2IconContainer,
	TreeItem2GroupTransition,
	TreeItem2Label,
	TreeItem2Root,
	TreeItem2Checkbox
} from '@mui/x-tree-view/TreeItem2';
import { useTranslation } from 'react-i18next';
import CustomIcon from 'app/main/icon/CustomIcon';

const useStyles = makeStyles({
	root: {
		color: '#202020',
		fontSize: '13px!important',
		width: '100%',
		padding: '0 20px!important',
		'&.full': { padding: '0!important' },
		'&>li:first-child': {
			borderTop: '1px dotted #aaa'
		},
		'& .tree-item-root': {
			position: 'relative',
			height: 40,
			'&:after': {
				position: 'absolute',
				bottom: 0,
				height: 0,
				width: 'calc(100% - 8px)',
				borderBottom: '1px dotted #aaa',
				content: '""',
				padding: '0px 28px 0 0'
			}
		}
	}
});

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
	color: '#202020',
	'&.selected': {
		backgroundColor: `rgba(0, 207, 226, 0.2)!important`,
		color: '#202020'
	},
	'&:hover': {
		backgroundColor: `#f5f7fa`,
		color: '#202020'
	}
}));

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
	const { t } = useTranslation('docbase');
	const {
		id,
		itemId,
		label,
		manageType,
		disabled,
		checked,
		onClickDelete,
		onClickAction,
		onClickAdd,
		onChangeInput,
		onChangeChecked,
		children,
		...other
	} = props;

	const {
		getRootProps,
		getContentProps,
		getIconContainerProps,
		getCheckboxProps,
		getLabelProps,
		getGroupTransitionProps,
		status
	} = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

	const [name, setName] = React.useState(label);
	return (
		<TreeItem2Provider itemId={itemId}>
			<TreeItem2Root {...getRootProps(other)}>
				<CustomTreeItemContent
					{...getContentProps({
						className: clsx('content tree-item-root', {
							expanded: status.expanded,
							selected: status.selected,
							focused: status.focused
						})
					})}
				>
					<TreeItem2IconContainer {...getIconContainerProps()}>
						<TreeItem2Icon status={status} />
					</TreeItem2IconContainer>
					{itemId < 0 ? (
						<>
							<Box sx={{ flexGrow: 1, display: 'flex', gap: 10, marginLeft: 5 }}>
								<TextField
									className=""
									type="text"
									fullWidth
									value={name}
									variant="outlined"
									size="small"
									onClick={e => {
										e.preventDefault();
										e.stopPropagation();
									}}
									onChange={e => {
										setName(e.target.value);
										onChangeInput(e);
										e.stopPropagation();
									}}
									onKeyDown={e => {
										//	키보드 입력시 목록 자동선택 방지.
										e.stopPropagation();
									}}
								/>
							</Box>
							<Box sx={{ flexGrow: 1, display: 'flex', gap: 1, flexDirection: 'row', paddingRight: 10 }}>
								<Tooltip title={t('CREATE')} arrow>
									<IconButton
										style={{ padding: 0, cursor: 'pointer' }}
										onClick={e => {
											onClickAdd(e, name);
											e.preventDefault();
											e.stopPropagation();
										}}
									>
										<Icon color="primary" fontSize="default">
											create_new_folder
										</Icon>
									</IconButton>
								</Tooltip>
							</Box>
						</>
					) : (
						<>
							<TreeItem2Checkbox
								{...getCheckboxProps({
									checked,
									onChange(e) {
										console.log(e);
										onChangeChecked(e.target.checked);
									}
								})}
							/>
							<Box sx={{ flexGrow: 1, display: 'flex', gap: 10, marginLeft: 5 }}>
								<CustomIcon className="" type="org-folder" fill="#202020" />
								<TreeItem2Label
									{...getLabelProps()}
									style={{ fontSize: '13px', fontFamily: 'S-CoreDream-Regu' }}
								/>
							</Box>
							{onClickDelete instanceof Function ? (
								<Box
									sx={{
										flexGrow: 1,
										display: 'flex',
										gap: 1,
										flexDirection: 'row-reverse',
										marginRight: 40
									}}
								>
									<Tooltip title={t('DELETE')} arrow>
										<IconButton
											style={{ padding: 0, cursor: 'pointer' }}
											onClick={e => {
												onClickDelete(e);
											}}
										>
											<CustomIcon type="delete" className="" fill="#575757" />
										</IconButton>
									</Tooltip>
								</Box>
							) : (
								<></>
							)}
							{onClickAction instanceof Function ? (
								<Box
									sx={{
										flexGrow: 1,
										display: 'flex',
										gap: 1,
										flexDirection: 'row-reverse',
										marginRight: 10
									}}
								>
									<Tooltip title={t('TAKEOUT_APPROVE_MANAGE_LOCK')} arrow>
										<IconButton
											style={{ padding: 0, cursor: 'pointer' }}
											onClick={e => {
												onClickAction(e);
											}}
										>
											{manageType === 0 ? (
												<Icon className="">lock_open</Icon>
											) : (
												<Icon className="">lock</Icon>
											)}
										</IconButton>
									</Tooltip>
								</Box>
							) : (
								<></>
							)}
						</>
					)}
				</CustomTreeItemContent>
				{children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
			</TreeItem2Root>
		</TreeItem2Provider>
	);
});

const TreeNode = React.memo(
	({
		node,
		childrenObj,
		fnOnClickHandler,
		fnOnClickDelete,
		fnOnClickAction,
		fnOnClickAdd,
		fnOnChangeInput,
		fnOnChangeChecked
	}) => {
		return (
			<CustomTreeItem
				itemId={node.fid}
				label={node.name}
				checked={node.checked}
				manageType={node.manage_type}
				className=""
				onChangeChecked={fnOnChangeChecked ? checked => fnOnChangeChecked(node.fid, node.parent_fid, checked) : null}
				onChangeInput={fnOnChangeInput ? e => fnOnChangeInput(e, node.fid, node.parent_fid) : null}
				onClickAdd={fnOnClickAdd ? (e, newName) => fnOnClickAdd(e, newName) : null}
				onClickDelete={
					fnOnClickDelete
						? e => {
								fnOnClickDelete(e, node);
								e.stopPropagation();
						  }
						: null
				}
				onClickAction={
					fnOnClickAction
						? e => {
								fnOnClickAction(e, node);
								e.stopPropagation();
						  }
						: null
				}
				onClick={e => {
					if (node.fid > 0) {
						fnOnClickHandler(e, node);
						e.stopPropagation();
					}
				}}
			>
				{childrenObj[node.fid] &&
					childrenObj[node.fid].children.map(childNode => (
						<TreeNode
							node={childNode}
							key={childNode.fid}
							childrenObj={childrenObj}
							fnOnClickHandler={fnOnClickHandler}
							fnOnChangeChecked={fnOnChangeChecked}
							fnOnChangeInput={fnOnChangeInput}
							fnOnClickAdd={fnOnClickAdd}
							fnOnClickDelete={fnOnClickDelete}
							fnOnClickAction={fnOnClickAction}
						/>
					))}
			</CustomTreeItem>
		);
	}
);

/**
 * 조직도 트리 생성 컴포넌ㅌ.
 *
 * 그려지는 조직을 배열로 만들어서 처리하지 않고 childrenObj에 하위 부서를 객체로 가지게해서 구성.
 * 배열로 만들경우 가져온 전체조직을 배열로 만들기위해 for을 여러번 작성해야하는 부분이있어(조직 오는 순서가 fid여서 부모가 무조건 먼저 온다고 할 수 없음)
 * 하위 부서를 가지는 객체를 만들어 처리하도록 구성
 *
 * @param {array} childrenObj
 * @param {function} fnOnClickHandler 클릭 핸들
 * @param {function} fnOnClickDelete  조직 삭제 버튼 함수
 * @returns
 */
const OrgTreeView = React.memo(
	({
		childrenObj,
		fnOnClickHandler,
		fnOnClickDelete,
		fnOnClickAction,
		fnOnClickAdd,
		fnOnChangeInput,
		fnOnChangeChecked,
		apiRef,
		expandedItems,
		checkbox,
		className,
		showTop
	}) => {
		const classes = useStyles();

		const [expanded, setExpanded] = React.useState([]);

		// React.useEffect(() => {
		// 	console.log(expanded);
		// }, [expanded]);

		React.useEffect(() => {
			setExpanded(expandedItems);
		}, [expandedItems]);

		const handleExpandedItemsChange = (e, itemIds) => {
			setExpanded(itemIds);
		};

		if (showTop && childrenObj[0]) {
			return (
				<SimpleTreeView
					className={clsx(classes.root, className)}
					checkboxSelection={checkbox}
					multiSelect
					apiRef={apiRef}
					expandedItems={expanded}
					onExpandedItemsChange={handleExpandedItemsChange}
				>
					<TreeNode
						node={childrenObj[0]}
						childrenObj={childrenObj}
						fnOnClickHandler={fnOnClickHandler}
						fnOnClickDelete={fnOnClickDelete}
						fnOnClickAction={fnOnClickAction}
						fnOnClickAdd={fnOnClickAdd}
						fnOnChangeInput={fnOnChangeInput}
						fnOnChangeChecked={fnOnChangeChecked}
					/>
				</SimpleTreeView>
			);
		}
		return (
			<SimpleTreeView
				className={clsx(classes.root, className)}
				checkboxSelection={checkbox}
				multiSelect
				apiRef={apiRef}
				expandedItems={expanded}
				onExpandedItemsChange={handleExpandedItemsChange}
			>
				{childrenObj[0] &&
					childrenObj[0].children &&
					childrenObj[0].children.length > 0 &&
					childrenObj[0].children.map(node => {
						return (
							<TreeNode
								node={node}
								childrenObj={childrenObj}
								fnOnClickHandler={fnOnClickHandler}
								fnOnClickDelete={fnOnClickDelete}
								fnOnClickAction={fnOnClickAction}
								fnOnClickAdd={fnOnClickAdd}
								fnOnChangeInput={fnOnChangeInput}
								fnOnChangeChecked={fnOnChangeChecked}
							/>
						);
					})}
			</SimpleTreeView>
		);
	}
);

export default OrgTreeView;
