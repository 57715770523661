import i18next from 'i18next';
import en from './navigation-i18n/en';
import ko from './navigation-i18n/ko';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('ko', 'navigation', ko);

const navigationConfig = [
	{
		id: 'setting_group',
		title: 'SETTING_GROUP',
		translate: 'SETTING_GROUP',
		type: 'collapse',
		children: [
			{
				id: 'user_management',
				title: 'USER_MANAGEMENT',
				translate: 'USER_MANAGEMENT',
				type: 'item',
				icon: 'person',
				url: '/users'
			},
			{
				id: 'org_management',
				title: 'ORG_MANAGEMENT',
				translate: 'ORG_MANAGEMENT',
				type: 'item',
				icon: 'org-folder',
				url: '/orgs'
			},
			{
				id: 'shared_management',
				title: 'SHARED_MANAGEMENT',
				translate: 'SHARED_MANAGEMENT',
				type: 'item',
				icon: 'shared-folder',
				url: '/shareds'
			},
			{
				id: 'global_setting',
				title: 'GLOBAL_SETTING',
				translate: 'GLOBAL_SETTING',
				type: 'item',
				icon: 'collect-global',
				url: '/g_collector'
			},
			{
				id: 'private_setting',
				title: 'PRIVATE_SETTING',
				translate: 'PRIVATE_SETTING',
				type: 'item',
				icon: 'collect-private',
				url: '/p_collector'
			},
			{
				id: 'white_process_management',
				title: 'WHITE_PROCESS_MANAGEMENT',
				translate: 'WHITE_PROCESS_MANAGEMENT',
				type: 'item',
				icon: 'fact_check',
				url: '/whiteProcesses'
			},
			{
				id: 'export_approval_management',
				title: 'EXPORT_APPROVAL_MANAGEMENT',
				translate: 'EXPORT_APPROVAL_MANAGEMENT',
				type: 'item',
				icon: 'face',
				url: '/exportApproval'
			},
			{
				id: 'offline_drive_management',
				title: 'OFFLINE_DRIVE_MANAGEMENT',
				translate: 'OFFLINE_DRIVE_MANAGEMENT',
				type: 'item',
				icon: 'local_laundry_service',
				url: '/offlineDrive'
			},
			{
				id: 'restore_setting',
				title: 'RESTORE_SETTING',
				translate: 'RESTORE_SETTING',
				type: 'item',
				icon: 'restore',
				url: '/restore'
			},
			{
				id: 'setup_info',
				title: 'SETUP_INFO',
				translate: 'SETUP_INFO',
				type: 'item',
				icon: 'laptop_windows',
				url: '/setup'
			},
			{
				id: 'server_setting',
				title: 'SERVER_SETTING',
				translate: 'SERVER_SETTING',
				type: 'item',
				icon: 'settings',
				url: '/settings'
			}
		]
	},
	{
		id: 'management_group',
		title: 'MANAGEMENT_GROUP',
		translate: 'MANAGEMENT_GROUP',
		type: 'collapse',
		children: [
			{
				id: 'dashboard',
				title: 'DASHBOARD',
				translate: 'DASHBOARD',
				type: 'item',
				icon: 'assessment',
				url: '/dashboard'
			},
			{
				id: 'accesslog',
				title: 'ACCESSLOG',
				translate: 'ACCESSLOG',
				type: 'item',
				icon: 'realtime',
				url: '/logs'
			},
			{
				id: 'holdFiles',
				title: 'HOLD_FILES',
				translate: 'HOLD_FILES',
				type: 'item',
				icon: 'hold-file',
				url: '/holdFiles'
			},
			{
				id: 'fileAccessLog',
				title: 'FILE_ACCESS_LOG',
				translate: 'FILE_ACCESS_LOG',
				type: 'item',
				icon: 'log-file',
				url: '/accessLogs'
			},
			{
				id: 'auditLog',
				title: 'AUDIT_LOG',
				translate: 'AUDIT_LOG',
				type: 'item',
				icon: 'log-audit',
				url: '/auditLogs'
			},
			{
				id: 'osr_management',
				title: 'OSR_MANAGEMENT',
				translate: 'OSR_MANAGEMENT',
				type: 'item',
				icon: 'osr',
				url: '/osr'
			},
			{
				id: 'securedocs_management',
				title: 'SECUREDOCS_MANAGEMENT',
				translate: 'SECUREDOCS_MANAGEMENT',
				type: 'item',
				icon: 'secdoc',
				url: '/securedocs'
			},
			{
				id: 'pii_management',
				title: 'PII_MANAGEMENT',
				translate: 'PII_MANAGEMENT',
				type: 'item',
				icon: 'pii',
				url: '/piiDocs'
			},
			{
				id: 'dup_management',
				title: 'DUP_MANAGEMENT',
				translate: 'DUP_MANAGEMENT',
				type: 'item',
				icon: 'dup',
				url: '/dupDocs'
			},
			{
				id: 'ransome_management',
				title: 'RANSOM_MANAGEMENT',
				translate: 'RANSOM_MANAGEMENT',
				type: 'item',
				icon: 'ransom',
				url: '/ransom'
			},
			{
				id: 'takeout_management',
				title: 'TAKEOUT_MANAGEMENT',
				translate: 'TAKEOUT_MANAGEMENT',
				type: 'item',
				icon: 'history_edu',
				url: '/takeouts'
			},
			{
				id: 'offline_takeout_management',
				title: 'OFFLINE_TAKEOUT_MANAGEMENT',
				translate: 'OFFLINE_TAKEOUT_MANAGEMENT',
				type: 'item',
				icon: 'description',
				url: '/offlineTakeouts'
			},
			{
				id: 'instantLog',
				title: 'INSTANT_LOG',
				translate: 'INSTANT_LOG',
				type: 'item',
				icon: 'assignment-late',
				url: '/instantLogs'
			},
			{
				id: 'clientRunningLog',
				title: 'CLIENT_RUNNING_LOG',
				translate: 'CLIENT_RUNNING_LOG',
				type: 'item',
				icon: 'desktop_mac',
				url: '/clientRunningLog'
			}
		]
	},
	{
		id: 'dlp_policy_group',
		title: 'DLP_POLICY_GROUP',
		translate: 'DLP_POLICY_GROUP',
		type: 'collapse',
		children: [
			{
				id: 'dlp_client_info',
				title: 'DLP_CLIENT_INFO',
				translate: 'DLP_CLIENT_INFO',
				type: 'item',
				icon: 'laptop_windows',
				url: '/dlp_client'
			},
			{
				id: 'dlp_policy_setting',
				title: 'DLP_POLICY_SETTING',
				translate: 'DLP_POLICY_SETTING',
				type: 'item',
				icon: 'file-cog-outline',
				url: '/dlp_policy_setup'
			},
			{
				id: 'dlp_group_setting',
				title: 'DLP_GROUP_SETTING',
				translate: 'DLP_GROUP_SETTING',
				type: 'item',
				icon: 'application-cog-outline',
				url: '/dlp_groups'
			}
		]
	},
	{
		id: 'dlp_log_group',
		title: 'DLP_LOG_GROUP',
		translate: 'DLP_LOG_GROUP',
		type: 'collapse',
		children: [
			{
				id: 'releaseBlockings',
				title: 'RELEASE_BLOCKING',
				translate: 'RELEASE_BLOCKING',
				type: 'item',
				icon: 'clipboard-text-clock-outline',
				url: '/releaseBlockings'
			},
			{
				id: 'printLog',
				title: 'PRINT_LOG',
				translate: 'PRINT_LOG',
				type: 'item',
				icon: 'history_edu',
				url: '/printLogs'
			},
			{
				id: 'clientAccessLog',
				title: 'CLIENT_ACCESS_LOG',
				translate: 'CLIENT_ACCESS_LOG',
				type: 'item',
				icon: 'calendar-text',
				url: '/clientAccessLogs'
			},
			{
				id: 'clientStorageLog',
				title: 'CLIENT_STORAGE_LOG',
				translate: 'CLIENT_STORAGE_LOG',
				type: 'item',
				icon: 'calendar-text',
				url: '/clientStorageLog'
			},
			{
				id: 'clientMonitoringLog',
				title: 'CLIENT_MONITORING_LOG',
				translate: 'CLIENT_MONITORING_LOG',
				type: 'item',
				icon: 'calendar-text',
				url: '/dlpMonitoringLogs'
			},
			{
				id: 'dlpClientAlertLog',
				title: 'CLIENT_ALERT_LOG',
				translate: 'CLIENT_ALERT_LOG',
				type: 'item',
				icon: 'alert-octagon-outline',
				url: '/dlpClientAlertLog'
			}
		]
	}
];

export default navigationConfig;
