import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	Slide,
	TextField,
	Card,
	Divider,
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';

import clsx from 'clsx';
import { getDirectOrgList, moveOrgFolderData } from 'app/store/docbase/admin/folderSlice';
import { showMessage, showErrorMessage } from 'app/store/fuse/messageSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import OpenDialogForm from '../component/OpenDialogForm';
import OrgTreeView from '../component/OrgTreeView';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
	root: {
		padding: 0,
		'& .MuiDialog-paper': {
			width: '100%'
		}
	},
	contentWrapper: {
		height: 555
	},
	title: {
		background: '#353b48',
		color: '#ffffff',
		fontSize: 16,
		paddingLeft: 30
	},
	body: {
		background: '#f2f3f5',
		padding: '24px 30px 30px',
		color: '#353b48',
		'&>.content': {
			borderRadius: 6
		},
		'&>.content>div': {
			display: 'flex',
			alignItems: 'center'
		},
		'&>.content>div>div': {
			flex: '1 1 auto'
		},
		'&>.content>div>.btn': { margin: '0 20px', flex: '0 1 auto' },
		'&>.content>div>div>.MuiButton-root': {
			color: '#333d4b',
			minWidth: 40,
			padding: 0,
			width: 40,
			background: '#eceff4',
			border: '1px solid #d9dee5'
		},
		'& .search-form': {
			padding: 12
		}
	},
	tableOrg: {
		'& .MuiTableRow-root.Mui-selected': {
			borderColor: 'rgba(0, 207, 226, 0.3)'
		}
	},
	buttonWrapper: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 12,
		'& .MuiButton-root': {},
		'& .MuiButton-root:first-child': {
			marginRight: 16
		}
	}
}));

export default function TransferDataDialog(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('docbase');
	const contentTable = React.useRef();

	const { orgsChildren, allOrgs } = useSelector(({ docbase }) => docbase.admin.folder);
	const [selectedObj, setSelectedObj] = React.useState({
		sourceID: 0,
		targetID: 0,
		sourcePath: '',
		targetPath: ''
	});
	const [orgs, setOrgs] = React.useState({ orgs: [], sourceOrgs: {}, targetOrgs: {} });
	const [expandedLeft, setExpandedLeft] = React.useState([]);
	const [expandedRight, setExpandedRight] = React.useState([]);

	const [textObj, setTextObj] = React.useState({
		sourceText: '',
		targetText: ''
	});

	React.useEffect(() => {
		if (props.open) {
			getOrgsData();
		}
	}, [props.open]);

	React.useEffect(() => {
		// const obj = {
		// 	sourceOrgs:
		// 		textObj.sourceText.length > 0 ? orgs.orgs.filter(org => org.name.indexOf(textObj.sourceText) >= 0) : orgs.orgs,
		// 	targetOrgs:
		// 		textObj.targetText.length > 0 ? orgs.orgs.filter(org => org.name.indexOf(textObj.targetText) >= 0) : orgs.orgs
		// };
		// setOrgs({
		// 	...orgs,
		// 	...obj
		// });
	}, [textObj]);

	React.useEffect(() => {
		console.log(orgs);
	}, [orgs]);

	async function getOrgsData() {
		const expanded = [];
		Object.keys(orgsChildren).forEach(key => {
			expanded.push(Number(key));
		});
		setOrgs({
			orgs: allOrgs,
			sourceOrgs: orgsChildren,
			targetOrgs: orgsChildren
		});
		setExpandedLeft(expanded);
		setExpandedRight(expanded);

		setTextObj({
			sourceText: '',
			targetText: ''
		});
		setSelectedObj({
			sourceID: 0,
			targetID: 0,
			sourcePath: '',
			targetPath: ''
		});
	}

	function handleExpandClick(direction) {
		const expanded = [];
		if (direction === 0) {
			if (expandedLeft.length === 0) {
				Object.keys(orgsChildren).forEach(key => {
					expanded.push(Number(key));
				});
				setExpandedLeft(expanded);
			} else {
				setExpandedLeft([]);
			}
		} else if (expandedRight.length === 0) {
			Object.keys(orgsChildren).forEach(key => {
				expanded.push(Number(key));
			});
			setExpandedRight(expanded);
		} else {
			setExpandedRight([]);
		}
	}

	const customList = type => (
		<Card>
			<div className="search-form">
				{type === 0 ? (
					<TextField
						className=""
						placeholder={t('SOURCE_ORG_NAME')}
						type="text"
						name="source-text"
						variant="outlined"
						value={textObj.sourceText}
						onChange={e => setTextObj({ ...textObj, sourceText: e.target.value })}
						fullWidth
					/>
				) : (
					<TextField
						className=""
						placeholder={t('TARGET_ORG_NAME')}
						type="text"
						name="target-text"
						variant="outlined"
						value={textObj.targetText}
						onChange={e => setTextObj({ ...textObj, targetText: e.target.value })}
						fullWidth
					/>
				)}
			</div>
			<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')}>
				{type === 0 ? (
					<div className="flex flex-col">
						<Button onClick={e => handleExpandClick(0)} style={{ alignSelf: 'flex-end' }}>
							{expandedLeft.length === 0 ? t('EXPAND_ALL') : t('COLLAPSE_ALL')}
						</Button>
						<OrgTreeView
							className="full"
							childrenObj={orgs.sourceOrgs}
							expandedItems={expandedLeft}
							fnOnClickHandler={(e, node) => {
								setSelectedObj({ ...selectedObj, sourceID: node.fid, sourcePath: node.disp_path });
							}}
						/>
					</div>
				) : (
					<div className="flex flex-col">
						<Button onClick={e => handleExpandClick(1)} style={{ alignSelf: 'flex-end' }}>
							{expandedRight.length === 0 ? t('EXPAND_ALL') : t('COLLAPSE_ALL')}
						</Button>
						<OrgTreeView
							className="full"
							childrenObj={orgs.targetOrgs}
							expandedItems={expandedRight}
							fnOnClickHandler={(e, node) => {
								setSelectedObj({ ...selectedObj, targetID: node.fid, targetPath: node.disp_path });
							}}
						/>
					</div>
				)}
			</FuseScrollbars>
		</Card>
	);

	return (
		<div>
			<Dialog
				className={classes.root}
				open={props.open}
				TransitionComponent={Transition}
				keepMounted
				fullWidth
				maxWidth="lg"
				onClose={props.fnHandleClose}
			>
				<DialogTitle className={classes.title} id="form-dialog-title" onClose={props.fnHandleClose}>
					{t('MOVE_ORG_DATA_TITLE')}
				</DialogTitle>
				<DialogContent className={classes.body}>
					<div className="content">
						<div>
							<div>{customList(0)}</div>
							<div className="btn">
								<Button
									variant="outlined"
									size="small"
									className={classes.button}
									onClick={e => {
										if (selectedObj.sourceID <= 0 || selectedObj.targetID <= 0) {
											return;
										}

										if (selectedObj.sourceID === selectedObj.targetID) {
											dispatch(showErrorMessage({ message: t('MOVE_ORG_DATA_ERROR_MESSAGE_1') }));
										} else {
											dispatch(
												openDialog({
													children: (
														<OpenDialogForm
															title={t('MOVE_ORG_DATA_TITLE')}
															content={t('MOVE_ORG_DATA_MESSAGE_1')}
															fnHandleOK={() => {
																moveOrgFolderData(selectedObj.sourceID, selectedObj.targetID)
																	.then(data => {
																		dispatch(
																			showMessage({
																				message: t('MOVE_ORG_DATA_MESSAGE_2')
																			})
																		);
																	})
																	.catch(err => {
																		dispatch(
																			showErrorMessage({
																				message: t('MOVE_ORG_DATA_ERROR_MESSAGE_2')
																			})
																		);
																	});
															}}
														/>
													)
												})
											);
										}
									}}
									disabled={selectedObj.sourceID <= 0 || selectedObj.targetID <= 0}
									aria-label="move selected right"
								>
									&gt;
								</Button>
							</div>
							<div>{customList(1)}</div>
						</div>
						<div className={classes.buttonWrapper}>
							<Button
								onClick={props.fnHandleClose}
								variant="contained"
								className="default"
								aria-label="cancel"
								type="button"
							>
								{t('CANCEL')}
							</Button>
							<Button
								variant="contained"
								color="secondary"
								className=""
								aria-label="action"
								type="button"
								onClick={e => {
									if (selectedObj.sourceID <= 0 || selectedObj.targetID <= 0) {
										return;
									}

									if (selectedObj.sourceID === selectedObj.targetID) {
										dispatch(showErrorMessage({ message: t('MOVE_ORG_DATA_ERROR_MESSAGE_1') }));
									} else {
										if (selectedObj.sourcePath.indexOf(selectedObj.targetPath) === 0) {
											dispatch(showErrorMessage({ message: '상위 부서로 이관은 지원되지 않습니다.' }));
											return;
										}
										if (selectedObj.targetPath.indexOf(selectedObj.sourcePath) === 0) {
											dispatch(showErrorMessage({ message: '하위 부서로 이관은 지원되지 않습니다.' }));
											return;
										}

										dispatch(
											openDialog({
												children: (
													<OpenDialogForm
														title={t('MOVE_ORG_DATA_TITLE')}
														content={t('MOVE_ORG_DATA_MESSAGE_1')}
														fnHandleOK={() => {
															moveOrgFolderData(selectedObj.sourceID, selectedObj.targetID)
																.then(data => {
																	dispatch(
																		showMessage({
																			message: t('MOVE_ORG_DATA_MESSAGE_2')
																		})
																	);
																})
																.catch(err => {
																	dispatch(
																		showErrorMessage({
																			message: t('MOVE_ORG_DATA_ERROR_MESSAGE_2')
																		})
																	);
																});
														}}
													/>
												)
											})
										);
									}
								}}
							>
								{t('TRANSFER')}
							</Button>
						</div>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
}
