import FuseScrollbars from '@fuse/core/FuseScrollbars';
import _ from '@lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableSortLabel,
	Checkbox,
	TableRow,
	Paper,
	Input,
	Button,
	Tooltip,
	IconButton,
	Icon
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import CustomIcon from 'app/main/icon/CustomIcon';
import FuseLoading from '@fuse/core/FuseLoading';

import { showErrorMessage, showMessage } from 'app/store/fuse/messageSlice';
import { getUserList } from 'app/store/docbase/admin/accountSlice';
import {
	clientsInfo,
	allowClientInstall,
	removeClient,
	clientBluetoothDevices,
	updateClient,
	clientInstallApps
} from 'app/store/docbase/admin/dlpSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import commonUtils from 'app/utils/CommonUtils';
import securityUtils from 'app/utils/SecurityUtils';
import ViewDialog from './ViewDialog';
import ViewInstalledDialog from './ViewInstalledDialog';

import OpenDialogForm from '../../component/OpenDialogForm';

const useStyles = makeStyles(theme => ({
	root: { borderRadius: 6 },
	deleteIcon: {
		color: '#ff6565'
	},
	contentWrapper: {
		height: `${window.innerHeight - 226}px`,
		padding: '0 20px',
		'& .health-module-title': {
			width: 85,
			lineHeight: '14px'
		}
	},
	icon: {
		width: 20,
		height: 20
	},
	btn: {
		width: '155px!important'
	}
}));

function DLPClientTable(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const contentTable = React.useRef();
	const { t } = useTranslation('docbase');

	const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

	const { usersObj } = useSelector(({ docbase }) => docbase.admin.account);
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState({
		id: 'user_id',
		direction: 'asc'
	});

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [clients, setClients] = useState([]);
	const [searchText, setSearchText] = React.useState('');
	const [dialogObj, setDialogObj] = React.useState({ open: false, logObj: {} });
	const [installedDialogObj, setInstalledDialogObj] = React.useState({ open: false, list: [] });
	const [bluetoothDevices, setBluetoothDevices] = React.useState([]);
	useEffect(() => {
		if (contentTable.current) {
			contentTable.current.style.height = `${innerHeight - 226}px`;
		}
		window.addEventListener('resize', () => setInnerHeight(window.innerHeight));
	}, [innerHeight]);

	useEffect(() => {
		if (Object.keys(usersObj).length === 0) {
			dispatch(getUserList()).then(() => {
				clientsInfo().then(data => {
					setClients(setClientsObj(data.clients, data.aes));
					setLoading(false);
				});
			});
		} else {
			clientsInfo().then(data => {
				setClients(setClientsObj(data.clients, data.aes));
				setLoading(false);
			});
		}
	}, [dispatch]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id
		});
	}

	if (loading) {
		return <FuseLoading />;
	}

	function setClientsObj(clientsList, aes) {
		const cs = [];
		clientsList.forEach(c1 => {
			const userID = securityUtils.decryptAES(aes, c1.user_id, 'hex', 'utf8');
			if (c1.clients.length === 0) {
				//	설치된 클라이언트만 보여주자..
				// cs.push({ user_id: userID, allow_client_install: c1.allow_client_install, isRemove: false });
			} else {
				c1.clients.forEach(c2 => {
					c2.mac_address = securityUtils.decryptAES(aes, c2.mac_address, 'hex', 'utf8');
					cs.push({ ...c2, user_id: userID, allow_client_install: c1.allow_client_install, isRemove: true });
				});
			}
		});
		console.log(cs);
		return cs;
	}

	function setAllowClient(userID, allow) {
		let msg = '';
		if (userID === '') {
			if (allow) {
				msg = t('DLP_CLIENT_MESSAGE_1');
			} else {
				msg = t('DLP_CLIENT_MESSAGE_2');
			}
		} else if (allow) {
			msg = t('DLP_CLIENT_MESSAGE_3');
		} else {
			msg = t('DLP_CLIENT_MESSAGE_4');
		}
		dispatch(
			openDialog({
				children: (
					<OpenDialogForm
						title={t('DLP_CLIENT_TITLE_1')}
						content={msg}
						fnHandleOK={() => {
							if (selectedUsers !== '') {
								let isErr = false;
								selectedUsers.forEach(selectID => {
									allowClientInstall(selectID, allow)
										.then(result => {
											if (!result) {
												isErr = true;
											}
										})
										.catch(err => {
											isErr = true;
											console.log(err);
										});
								});
								if (isErr) {
									dispatch(
										showErrorMessage({
											message: t('DLP_CLIENT_MESSAGE_5')
										})
									);
								}
								clientsInfo(searchText).then(data => {
									setClients(setClientsObj(data.clients, data.aes));
									setLoading(false);
								});
							} else {
								allowClientInstall(userID, allow)
									.then(result => {
										if (result) {
											dispatch(
												showMessage({
													message: t('DLP_CLIENT_MESSAGE_6')
												})
											);
										}
									})
									.catch(err => {
										console.log(err);
										dispatch(
											showErrorMessage({
												message: t('DLP_CLIENT_MESSAGE_5')
											})
										);
									})
									.finally(() => {
										clientsInfo(searchText).then(data => {
											setClients(setClientsObj(data.clients, data.aes));
											setLoading(false);
										});
									});
							}
						}}
					/>
				)
			})
		);
	}

	function selectUser(event, id) {
		const selectedIndex = selectedUsers.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selectedUsers, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selectedUsers.slice(1));
		} else if (selectedIndex === selectedUsers.length - 1) {
			newSelected = newSelected.concat(selectedUsers.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selectedUsers.slice(0, selectedIndex), selectedUsers.slice(selectedIndex + 1));
		}

		setSelectedUsers(newSelected);
	}

	console.log(clients);
	return (
		<div className={clsx(classes.root, 'w-full p-0 h-full')}>
			<ViewDialog
				open={dialogObj.open}
				logObj={dialogObj.logObj}
				bluetoothDevices={bluetoothDevices}
				fnHandleClose={() => setDialogObj({ open: false, logObj: {} })}
			/>
			<ViewInstalledDialog
				open={installedDialogObj.open}
				list={installedDialogObj.list}
				fnHandleClose={() => setInstalledDialogObj({ open: false, list: [] })}
			/>
			<Paper className={clsx('mx-auto print:w-full print:p-8 print:shadow-none rounded-8 relative')}>
				<div className="table-search-wrapper">
					<div className="">
						<div className="input-item">
							<Input
								placeholder={t('SEARCH')}
								className="search-input"
								disableUnderline
								fullWidth
								value={searchText}
								inputProps={{
									'aria-label': 'Search'
								}}
								onChange={ev => setSearchText(ev.target.value)}
							/>
						</div>
					</div>
					<div style={{ marginLeft: 10 }}>
						<Button
							variant="contained"
							className="default"
							type="button"
							onClick={() => {
								clientsInfo(searchText).then(data => {
									setClients(setClientsObj(data.clients, data.aes));
									setLoading(false);
								});
							}}
						>
							{t('SEARCH')}
						</Button>
					</div>
					<div className="total-cnt-info">
						<span className="pipe">|</span>
						<span>
							{t('TOTAL')} <span className="count">{clients.length}</span>
							{t('CNT')}
						</span>
					</div>
					<div className="btn-group">
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() => {
									dispatch(
										openDialog({
											children: (
												<OpenDialogForm
													title={t('DLP_CLIENT_TITLE_2')}
													content={t('DLP_CLIENT_MESSAGE_7')}
													fnHandleOK={() => {
														updateClient();
													}}
												/>
											)
										})
									);
								}}
							>
								{t('FORCED_UPDATE')}
							</Button>
						</div>
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() => {
									setAllowClient(selectedUsers, true);
								}}
							>
								{t('ALLOW_INSTALL')}
							</Button>
						</div>
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() => {
									setAllowClient(selectedUsers, false);
								}}
							>
								{t('LOCK_INSTALL')}
							</Button>
						</div>
						<div>
							<Button
								variant="contained"
								className="default"
								type="button"
								onClick={() => {
									setAllowClient('', true);
								}}
							>
								{t('ALL_ALLOW_INSTALL')}
							</Button>
						</div>
						<div>
							<Button
								variant="contained"
								className={clsx(classes.btn, 'default')}
								type="button"
								onClick={() => {
									setAllowClient('', false);
								}}
							>
								{t('ALL_LOCK_INSTALL')}
							</Button>
						</div>
					</div>
				</div>
				<FuseScrollbars className={clsx(classes.contentWrapper, 'flex-grow overflow-x-auto')} ref={contentTable}>
					<Table stickyHeader className="" aria-labelledby="tableTitle">
						<TableHead>
							<TableRow>
								<TableCell padding="none" className="w-40 md:w-64 text-center z-99">
									<Checkbox
										color="secondary"
										size="small"
										indeterminate={selectedUsers.length > 0 && selectedUsers.length < clients.length}
										checked={clients.length !== 0 && selectedUsers.length === clients.length}
										onChange={e => {
											const newSelected = [];
											if (e.target.checked) {
												clients.forEach(d => {
													newSelected.push(d.user_id);
												});
											}
											setSelectedUsers(newSelected);
										}}
									/>
								</TableCell>

								<TableCell className="left" align="left" padding="default" sortDirection={order.direction}>
									<TableSortLabel
										active={order.id === 'id'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'id')}
									>
										{t('USER')}
									</TableSortLabel>
								</TableCell>
								<TableCell
									className="left"
									align="left"
									padding="default"
									sortDirection={order.direction}
									style={{ width: '100px' }}
								>
									<TableSortLabel
										active={order.id === 'lock'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'lock')}
									>
										{t('INSTALL')}
									</TableSortLabel>
								</TableCell>
								<TableCell
									className=""
									align="center"
									padding="default"
									sortDirection={order.direction}
									style={{ width: '180px' }}
								>
									<TableSortLabel
										active={order.id === 'mac'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'mac')}
									>
										{t('MAC')}
									</TableSortLabel>
								</TableCell>
								<TableCell
									id="version"
									className=""
									align="center"
									padding="default"
									sortDirection={order.direction}
									style={{ width: '100px' }}
								>
									<TableSortLabel
										active={order.id === 'version'}
										direction={order.direction}
										onClick={event => handleRequestSort(event, 'version')}
									>
										{t('VERSION')}
									</TableSortLabel>
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('LOGIN')}
								</TableCell>
								<TableCell className="" align="center" padding="default" sortDirection={order.direction}>
									{t('CONNECTED')}
								</TableCell>
								<TableCell
									className=""
									align="center"
									padding="default"
									sortDirection={order.direction}
									style={{ width: '120px' }}
								>
									{t('LOGGED_IN_AT')}
								</TableCell>
								<TableCell
									className=""
									align="center"
									padding="default"
									sortDirection={order.direction}
									style={{ width: '120px' }}
								>
									{t('LOGGED_OFF_AT')}
								</TableCell>
								<TableCell
									className="health-module-title"
									align="center"
									padding="default"
									sortDirection={order.direction}
								>
									{t('DLP_HEALTH_MODULE_SECURE')}
								</TableCell>
								<TableCell
									className="health-module-title"
									align="center"
									padding="default"
									sortDirection={order.direction}
								>
									{t('DLP_HEALTH_MODULE_EPS')}
								</TableCell>
								<TableCell
									className="health-module-title"
									align="center"
									padding="default"
									sortDirection={order.direction}
								>
									{t('DLP_HEALTH_MODULE_NETWORK')}
								</TableCell>
								<TableCell
									className="health-module-title"
									align="center"
									padding="default"
									sortDirection={order.direction}
								>
									{t('DLP_HEALTH_MODULE_CHROME')
										.split(' ')
										.map((word, index, arr) => (
											<>
												{word}
												{index < arr.length - 1 && <br />}
											</>
										))}
								</TableCell>
								<TableCell
									className=""
									align="center"
									padding="default"
									sortDirection={order.direction}
									style={{ width: 150 }}
								>
									{t('')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{_.orderBy(
								clients,
								[
									d => {
										switch (order.id) {
											case 'id': {
												return d.user_id;
											}
											case 'version': {
												return d.version;
											}
											case 'mac': {
												return d.version;
											}
											case 'lock': {
												return d.allow_client_install;
											}
											default: {
												return d[order.id];
											}
										}
									}
								],
								[order.direction]
							).map(d => {
								const isSelected = selectedUsers.indexOf(d.user_id) >= 0;
								return (
									<TableRow
										className="cursor-pointer"
										hover
										role="checkbox"
										aria-checked={isSelected}
										tabIndex={-1}
										key={d.user_id}
										selected={isSelected}
										onClick={event => selectUser(event, d.user_id)}
									>
										<TableCell className="text-center table-checkbox" padding="none">
											<Checkbox
												color="secondary"
												size="small"
												checked={isSelected}
												onClick={event => event.stopPropagation()}
												onChange={event => selectUser(event, d.user_id)}
											/>
										</TableCell>
										<TableCell className="left" component="td" scope="row">
											{`${d.user_id}(${usersObj[d.user_id] ? usersObj[d.user_id].name : ''})`}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.allow_client_install ? t('PERMIT') : t('BLOCK')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.mac_address}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.version}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.logged_in ? t('LOGIN') : '-'}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.connected ? t('CONNECTED') : t('NOT_CONNECTED')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{commonUtils.getDateStringToLocalDate(d.last_logged_in_at)}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{commonUtils.getDateStringToLocalDate(d.last_logged_off_at)}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.health_module_secure === 1
												? t('CLIENT_RUNNING_TYPE_START')
												: t('CLIENT_RUNNING_TYPE_STOP')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.health_module_eps === 1 ? t('ACTIVE') : t('INACTIVE')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.health_module_network === 1 ? t('ACTIVE') : t('INACTIVE')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.health_module_chrome < 0
												? t('NOT_INSTALLED')
												: d.health_module_chrome === 1
												? t('ACTIVE')
												: t('INACTIVE')}
										</TableCell>
										<TableCell className="" component="td" scope="row" align="center">
											{d.isRemove ? (
												<div>
													<Tooltip title={t('INSTALL_LIST')} arrow>
														<IconButton
															onClick={e => {
																clientInstallApps(d.client_id).then(list => {
																	setInstalledDialogObj({ open: true, list });
																});
															}}
														>
															<Icon style={{ color: '#575757' }}>list_alt;</Icon>
														</IconButton>
													</Tooltip>
													<Tooltip title={t('DLP_CLIENT_LOG_VIER')} arrow>
														<IconButton
															onClick={e => {
																clientBluetoothDevices(d.client_id).then(list =>
																	setBluetoothDevices(list)
																);
																setDialogObj({
																	open: true,
																	logObj: {
																		...d,
																		user_name: usersObj[d.user_id]
																			? usersObj[d.user_id].name
																			: ''
																	}
																});
															}}
														>
															<Icon style={{ color: '#575757' }}>pageview</Icon>
														</IconButton>
													</Tooltip>
													<Tooltip title={t('DELETE')} arrow>
														<IconButton
															onClick={e => {
																dispatch(
																	openDialog({
																		children: (
																			<OpenDialogForm
																				title={t('DLP_CLIENT_DELETE_TITLE')}
																				content={t('DLP_CLIENT_DELETE_CONTENT')}
																				fnHandleOK={() => {
																					removeClient(d.user_id, d.mac_address)
																						.then(result => {
																							if (result) {
																								dispatch(
																									showMessage({
																										message:
																											t(
																												'DLP_CLIENT_DELETE_SUCCESS'
																											)
																									})
																								);
																							}
																						})
																						.catch(err => {
																							console.log(err);
																							dispatch(
																								showErrorMessage({
																									message:
																										t(
																											'DLP_CLIENT_DELETE_FAIL'
																										)
																								})
																							);
																						})
																						.finally(() => {
																							clientsInfo(searchText).then(data => {
																								setClients(
																									setClientsObj(
																										data.clients,
																										data.aes
																									)
																								);
																								setLoading(false);
																							});
																						});
																				}}
																			/>
																		)
																	})
																);
															}}
														>
															<CustomIcon type="delete" className="" fill="#575757" />
														</IconButton>
													</Tooltip>
												</div>
											) : (
												<></>
											)}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</FuseScrollbars>
			</Paper>
		</div>
	);
}

export default DLPClientTable;
